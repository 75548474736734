import { I18nKey } from "../../../../i18n";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, FontSizeType } from "theme/ThemeTypes";
import { Types } from "../../../types";
import {
  Keyboard,
  StyleProp,
  TextInput,
  TextInputProps,
  TextStyle,
  TouchableOpacity,
  View,
  ViewStyle,
} from "react-native";
import React from "react";
import { useTranslation } from "react-i18next";
import fnStyles from "./JTextInputStyle";
import { Icon } from "../icon";
import { JLabel } from "../text/JLabel";

type Props = {
  name?: string;
  value: string;
  onChangeValue: React.Dispatch<React.SetStateAction<string>>;
  placeHolder?: string;
  onBlur?: () => void;
  error?: boolean;
  label?: string;
  labelAlreadyShown?: boolean;
  labelKey?: I18nKey;
  valuesKey?: object;
  iconType?: Types["iconTypes"];
  icon?: string;
  colorName?: ColorType;
  fontWeight?: Types["fontWeights"];
  sizeName?: FontSizeType;
  isUppercase?: boolean;
  isBold?: boolean;
  isItalic?: boolean;
  centered?: boolean;
  right?: boolean;
  elevation?: number;
  containerStyle?: StyleProp<ViewStyle>;
  textInputStyle?: StyleProp<TextStyle>;
  iconStyle?: StyleProp<ViewStyle>;
  props?: TextInputProps;
  keyboardType?: Types["keyboardTypes"];
  inputType?: Types["inputTypes"];
  accessibilityLabel?: string;
  accessibilityLabelledBy?: string;
  autocomplete?: TextInputProps["autoComplete"];
};

export const JTextInput: React.FC<Props> = ({
  name,
  value,
  onChangeValue,
  placeHolder = "",
  onBlur = () => {},
  error = false,
  label,
  labelAlreadyShown = false,
  labelKey,
  valuesKey = {},
  iconType = "FontAwesome",
  icon,
  colorName,
  fontWeight,
  sizeName,
  isUppercase = false,
  isBold = false,
  isItalic = false,
  centered = false,
  right = false,
  elevation = 0,
  containerStyle,
  textInputStyle,
  iconStyle,
  keyboardType = "default",
  inputType = "default",
  accessibilityLabel,
  accessibilityLabelledBy,
  autocomplete,
  props,
}: Props) => {
  const { t } = useTranslation();

  const { theme } = useTheme();
  const styles = fnStyles(theme, inputType);

  const labelResult = labelKey ? t(labelKey, valuesKey) : label || "input";

  const placeholderResult = placeHolder;

  const [show, setShow] = React.useState(false);

  const font = () => {
    const defaultColor = theme.colors.dark;
    const defaultSize = theme.fontSizes.large;

    return {
      fontSize: sizeName
        ? theme.normalize(theme.fontSizes[sizeName])
        : theme.normalize(defaultSize),
      color: colorName ? theme.colors[colorName] : defaultColor,
    };
  };

  const templateFont = font();

  return (
    <View style={containerStyle}>
      {!labelAlreadyShown && !!label && <JLabel label={label} id={`${name}`} />}
      <View
        style={[
          styles.container,
          elevation ? styles.containerElevated : {},
          elevation ? { elevation } : {},
          error ? styles.containerError : {},
        ]}
      >
        {icon && (
          <Icon
            name={icon}
            type={iconType}
            style={[styles.icon, iconStyle]}
            colorName="greyDark"
          />
        )}
        <TextInput
          style={[
            styles.text,
            // eslint-disable-next-line react-native/no-inline-styles
            isBold ? { fontWeight: "700" } : { fontWeight },
            // eslint-disable-next-line react-native/no-inline-styles
            isUppercase && { textTransform: "uppercase" },
            templateFont,
            // eslint-disable-next-line react-native/no-inline-styles
            {
              textAlign: centered ? "center" : right ? "right" : "left",
              fontStyle: isItalic ? "italic" : "normal",
              flex: 1,
            },
            textInputStyle,
            error ? styles.textError : {},
          ]}
          value={value}
          onChangeText={onChangeValue}
          secureTextEntry={inputType === "password" && !show}
          placeholder={placeholderResult}
          placeholderTextColor={
            error ? theme.colors.dangerLight : theme.colors.grey
          }
          keyboardType={keyboardType}
          passwordRules={
            inputType === "password"
              ? "required: upper; required: lower; required: digit; required: special; max-consecutive: 2; minlength: 8;"
              : undefined
          }
          onSubmitEditing={Keyboard.dismiss}
          onBlur={onBlur}
          autoCapitalize={"none"}
          selectionColor={theme.colors.tinyElementsBackground}
          aria-label={accessibilityLabel || labelResult}
          aria-labelledby={accessibilityLabelledBy} // useless while no external labels
          // TODO si on a un bug sur les messages pour la taille du composant, c'est ici
          // onContentSizeChange={(event) => {
          //   setHeight(theme.normalize(event.nativeEvent.contentSize.height));
          // }}
          multiline={inputType === "textarea"}
          numberOfLines={inputType === "textarea" ? 5 : undefined}
          autoComplete={autocomplete}
          id={name}
          {...props}
        />
        {inputType === "password" && (
          <TouchableOpacity
            onPress={() => setShow(!show)}
            role="button"
            aria-pressed={show}
            aria-label={t("labels.togglePasswordShow")}
          >
            <Icon
              name={show ? "ios-eye" : "ios-eye-off"}
              type="IonIcons"
              style={styles.eye}
            />
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};
