import { StyleProp, ViewStyle, View } from "react-native";
import React from "react";
import { Clickable } from "../clickable";
import fnStyles from "./CardStyle";
import useTheme from "../../../theme/ThemeProvider";

type Props = {
  onPress?: any;
  disabled?: boolean;
  noShadow?: boolean;
  ariaCurrent?: boolean;
  expandable?: boolean;
  ariaExpanded?: boolean;
  style?: StyleProp<ViewStyle>;
  children: React.ReactNode[] | React.ReactNode;
  id?: string;
};

export const Card: React.FC<Props> = ({
  onPress = null,
  disabled = false,
  noShadow = false,
  ariaCurrent,
  ariaExpanded,
  style = {},
  children,
  id = undefined,
}: Props) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme, noShadow);

  return onPress ? (
    <Clickable
      onPress={onPress}
      disabled={disabled}
      style={[styles.container, style]}
      ariaCurrent={ariaCurrent}
      ariaExpanded={ariaExpanded}
      id={id}
    >
      {children}
    </Clickable>
  ) : (
    <View
      style={[styles.container, style]}
      aria-expanded={ariaExpanded}
      aria-current={ariaCurrent}
      nativeID={id}
    >
      {children}
    </View>
  );
};
