import React from "react";
import { FlatList, RefreshControl, StyleProp, ViewStyle } from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { Post } from "../../types/Post";
import { Spacer } from "../jlib";
import { PostItem } from "./PostItem";
import { List } from "components/jlib/list/List";

type Props = {
  data: Post[] | undefined;
  onPress: (post: Post, firstFaq?: boolean) => void;
  onSitePressed?: (post: Post) => void;
  refreshing?: boolean;
  hasRefresh?: boolean;
  postLevel?: number;
  onRefresh?: () => void;
  contentContainerStyle?: StyleProp<ViewStyle>;
};

export const PostsList: React.FC<Props> = ({
  data,
  postLevel,
  onPress,
  refreshing,
  hasRefresh = true,
  onRefresh,
  contentContainerStyle,
  onSitePressed,
}) => {
  const { theme } = useTheme();

  const spacer = () => (
    <Spacer height={"medium"} backgroundColor={"transparent"} />
  );
  return hasRefresh ? (
    <List
      data={data}
      renderItem={({ item }) => (
        <PostItem
          postLevel={postLevel}
          post={item}
          onPress={onPress}
          onSitePressed={onSitePressed}
        />
      )}
      keyExtractor={(item) => `${item.id}`}
      ItemSeparatorComponent={spacer}
      contentContainerStyle={[
        {
          paddingTop: theme.normalize(20),
          paddingBottom: theme.normalize(150),
        },
        contentContainerStyle,
      ]}
      refreshControl={
        <RefreshControl refreshing={refreshing!} onRefresh={onRefresh} />
      }
      role="list"
    />
  ) : (
    <List
      data={data}
      renderItem={({ item }) => (
        <PostItem
          postLevel={postLevel}
          post={item}
          onPress={onPress}
          onSitePressed={onSitePressed}
        />
      )}
      keyExtractor={(item) => `${item.id}`}
      ItemSeparatorComponent={spacer}
      contentContainerStyle={[
        {
          paddingTop: theme.normalize(20),
          paddingBottom: theme.normalize(150),
        },
        contentContainerStyle,
      ]}
    />
  );
};
