import React from "react";
import { Image, ImageProps, ImageStyle, StyleProp } from "react-native";
import { Types } from "../../../types";
import { images } from "./images";
import { REACT_APP_API_ROOT } from "../../../constants";
//import { SvgUri } from "react-native-svg";

type Props = ImageProps & {
  type?: Types["imageTypes"];
  style?: StyleProp<ImageStyle>;
  source: string;
  resizeMode?: any;
  alt?: string; // keep empty for decorations
};

export const JImage = (props: Props) => {
  const { type, style, source, resizeMode, alt, ...otherProps } = props;

  const actualSource = type === "Local" ? images[source] : source;

  switch (type) {
    case "Local":
      return <Image aria-label={alt} style={style} source={actualSource} />;
    case "Uri":
    default:
      return (
        <Image
          style={style}
          source={{
            uri: `${REACT_APP_API_ROOT}${actualSource}`,
          }}
          resizeMode={resizeMode || "cover"}
          aria-label={alt}
          {...otherProps}
        />
      );
  }
};
