import { Platform, StyleProp, TextStyle, View, ViewStyle } from "react-native";
import React from "react";
import { I18nKey } from "../../../../i18n";
import useTheme from "../../../theme/ThemeProvider";
import { FontSizeType } from "theme/ThemeTypes";
import { Clickable } from "../clickable";
import { Icon } from "../icon";
import { JText } from "../text";
import { Row } from "../row";
import { JLabel } from "../text/JLabel";
import { t } from "i18next";

type Props = {
  name?: string;
  value: boolean;
  onPress: () => void;
  onColor?: string;
  offColor?: string;
  label?: string;
  labelKey?: I18nKey;
  labelStyle?: StyleProp<TextStyle>;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  shape?: "squared" | "rounded" | "circle";
  adaptToPlatform?: boolean;
  style?: StyleProp<ViewStyle> | null;
};

export const Checkbox: React.FC<Props> = ({
  value,
  onPress,
  onColor,
  offColor,
  name,
  label,
  labelKey,
  labelStyle,
  disabled = false,
  size = "medium",
  shape = "rounded",
  adaptToPlatform = true,
  style = null,
}: Props) => {
  const { theme } = useTheme();

  const boxSizes = {
    small: theme.normalize(theme.fontSizes.medium),
    medium: theme.normalize(theme.fontSizes.mediumLarge),
    large: theme.normalize(theme.fontSizes.large),
  };

  interface Map {
    [key: string]: FontSizeType;
  }

  const iconSizes: Map = {
    small: "medium",
    medium: "mediumLarge",
    large: "large",
  };

  const boxRadius = {
    squared: 0,
    rounded: theme.normalize(theme.radius.small),
    circle: boxSizes[size] / 2,
  };

  if (adaptToPlatform) {
    shape = Platform.OS === "ios" ? "circle" : "rounded";
  }

  onColor = onColor ?? theme.colors.tinyElementsBackground;
  offColor = offColor ?? theme.colors.white;

  const textLabel = label || (labelKey ? t(labelKey) : null);

  if (textLabel) {
    return (
      <Row align={"center"} style={style}>
        <Clickable
          onPress={onPress}
          disabled={disabled}
          style={{ flexDirection: "row" }}
        >
          <View
            role="checkbox"
            aria-checked={Boolean(value)}
            nativeID={name}
            /* eslint-disable-next-line react-native/no-inline-styles */
            style={{
              backgroundColor: value ? onColor : offColor,
              width: boxSizes[size],
              height: boxSizes[size],
              borderRadius: boxRadius[shape],
              marginRight: theme.normalize(10),
              borderWidth: value ? 0 : 1,
              borderColor: theme.colors.dark,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {value ? (
              <Icon
                name={"check"}
                type={"Feather"}
                colorName={"white"}
                sizeName={iconSizes[size]}
              />
            ) : null}
          </View>
          <JLabel label={textLabel} id={name ?? ""} />
        </Clickable>
      </Row>
    );
  } else {
    return (
      <Clickable onPress={onPress} disabled={disabled} style={style}>
        <View
          role="checkbox"
          aria-checked={Boolean(value)}
          nativeID={name}
          /* eslint-disable-next-line react-native/no-inline-styles */
          style={{
            backgroundColor: value ? onColor : offColor,
            width: boxSizes[size],
            height: boxSizes[size],
            borderRadius: boxRadius[shape],
            marginRight: theme.normalize(10),
            borderWidth: value ? 0 : 1,
            borderColor: theme.colors.dark,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {value ? (
            <Icon
              name={"check"}
              type={"Feather"}
              colorName={"white"}
              sizeName={iconSizes[size]}
            />
          ) : null}
        </View>
      </Clickable>
    );
  }
};
