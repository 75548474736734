import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import isDesktopMode, { isTopBarMode } from "components/utils/deviceMode";
import React, { FC, useEffect, useState } from "react";
import { Animated, Dimensions, View, useWindowDimensions } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import ItemInteraction from "./ItemInteraction";
import fnStyles from "./TabBarStyle";
import { BoxedLayout } from "../layout/BoxedLayout";
import { NavbarLogo } from "../header";
import useConfiguration from "../../../configuration/ConfigurationProvider";

export const TabBar: FC<BottomTabBarProps & { isShown: boolean }> = ({
  state,
  descriptors,
  navigation,
  isShown = true,
}: BottomTabBarProps & { isShown: boolean }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();

  const isTopBar = isTopBarMode({ width, height });

  const totalWidth = Dimensions.get("window").width;
  const tabWidth = isTopBar
    ? theme.layout.containerMaxWidth / state.routes.length
    : totalWidth / state.routes.length;
  const styles = fnStyles(theme, isTopBar, tabWidth);

  const [translateValue] = useState(new Animated.Value(0));

  useEffect(() => {
    if (isShown) {
      const animateSlider = (index: number) => {
        Animated.spring(translateValue, {
          toValue: index * tabWidth,
          velocity: 10,
          useNativeDriver: true,
        }).start();
      };

      animateSlider(state.index);
    }
  }, [state.index, tabWidth, translateValue, isTopBar, theme, isShown]);

  return isShown ? (
    isTopBar ? (
      <View style={styles.container} role="banner" accessibilityRole="header">
        <View style={styles.logo}>
          <NavbarLogo />
        </View>
        <BoxedLayout
          style={styles.box}
          role="navigation"
          aria-label="Menu principal"
        >
          <View role="list" style={styles.box}>
            {state.routes.map((route, index) => (
              <ItemInteraction
                key={index}
                state={state}
                descriptors={descriptors}
                navigation={navigation}
                route={route}
                index={index}
              />
            ))}
            <Animated.View
              style={[
                styles.slider,
                {
                  transform: [{ translateX: translateValue }],
                },
              ]}
            />
          </View>
        </BoxedLayout>

        {isDesktopMode({ width, height }) && (
          <View style={{ width: configuration?.logo?.width, opacity: 0 }} />
        )}
      </View>
    ) : (
      <View style={styles.container} role="banner" accessibilityRole="header">
        <View role="list" style={styles.box2}>
          {state.routes.map((route, index) => (
            <ItemInteraction
              key={index}
              state={state}
              descriptors={descriptors}
              navigation={navigation}
              route={route}
              index={index}
            />
          ))}
        </View>
        <Animated.View
          style={[
            styles.slider,
            {
              transform: [{ translateX: translateValue }],
            },
          ]}
        />
      </View>
    )
  ) : null;
};
