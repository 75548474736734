import { StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBar: boolean) =>
  StyleSheet.create({
    contentContainer: {
      flex: 1,
      //minHeight: "100%",
    },
    header: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      alignItems: "center",
      gap: 8,
      marginBottom: theme.sizings.mediumLarge,
    },
    headerMainTilte: {
      zIndex: 10,
    },
    boxLayout: {
      height: "100%",
    },
    touchableContainer: {
      position: "relative",
      top: -7,
      marginLeft: theme.sizings.small,
    },
    stack: {
      marginHorizontal: 2,
    },
    chatContainer: {
      justifyContent: "space-between",
      flex: 1,
      /*paddingBottom: !isTopBar
        ? theme.layout.navBottomHeight + theme.sizings.medium
        : 0,*/
    },
    react: {
      flexDirection: "row",
      flexWrap: "wrap",
      justifyContent: "center",
      alignItems: "center",
    },
    imageInChat: {
      width: 200,
      height: 200,
      marginTop: theme.sizings.medium,
      //borderRadius: theme.radius.mediumLarge,
    },
    selectedImages: {
      backgroundColor: theme.colors.white,
      padding: theme.sizings.small,
      borderTopLeftRadius: theme.radius.mediumLarge,
      borderTopRightRadius: theme.radius.mediumLarge,
    },
    removeImage: {
      backgroundColor: theme.colors.white,
      borderRadius: 50,
      flex: 1,
      position: "absolute",
      top: -5,
      right: -5,
    },
    selectedImage: {
      height: theme.normalize(100),
      width: theme.normalize(100),
      borderRadius: 15,
    },
    documentInChat: {
      width: 200,
      height: 100,
      borderRadius: 15,
      backgroundColor: theme.colors.info,
      justifyContent: "center",
      alignItems: "center",
      marginBottom: theme.sizings.smallMedium,
    },
    documentIcon: {
      marginBottom: theme.sizings.smallMedium,
      marginLeft: theme.sizings.small,
    },
    chatSendButton: {
      marginBottom: theme.sizings.smallMedium,
      marginRight: theme.sizings.smallMedium,
    },
    inputStyle: {
      paddingBottom: theme.sizings.large,
      paddingTop: theme.sizings.smallMedium,
      paddingHorizontal: theme.sizings.smallMedium,
      backgroundColor: isTopBar ? undefined : theme.colors.white, //theme.colors.headerBackground,
    },
    textInputStyle: {
      backgroundColor: theme.colors.slate,
      borderRadius: 20,
      borderWidth: 1,
      borderColor: theme.colors.greyLight,
    },
    icon: {
      alignItems: "center",
      width: "100%",
      paddingTop: theme.normalize(80),
    },
    image: {
      width: theme.normalize(120),
      height: theme.normalize(120),
    },
    text: {
      width: "80%",
      letterSpacing: 0,
      marginTop: theme.normalize(40),
    },
  });
