import { Dimensions, StyleSheet } from "react-native";
import { Theme } from "theme/ThemeTypes";

export default (theme: Theme, isTopBarMode: boolean, tabWidth: number) =>
  StyleSheet.create({
    container: isTopBarMode
      ? {
          height: theme.layout.navBottomHeight,
          width: Dimensions.get("window").width,
          flexDirection: "row",
          backgroundColor: "white",
          position: "absolute",
          top: 0,
          shadowOffset: {
            width: 0,
            height: -theme.normalize(5),
          },
          shadowOpacity: 0.2,
          zIndex: 1000,
          shadowRadius: 16.0,
          elevation: 4,
          justifyContent: "center",
          paddingHorizontal: theme.sizings.medium,
        }
      : {
          height: theme.layout.navBottomHeight,
          width: Dimensions.get("window").width,
          flexDirection: "row",
          backgroundColor: "white",
          position: "absolute",
          bottom: 0,
          zIndex: 1000,
          shadowOffset: {
            width: 0,
            height: -theme.normalize(5),
          },
          shadowOpacity: 0.2,
          shadowRadius: 16.0,
          elevation: 4,
          borderTopLeftRadius: theme.radius.large / 2,
          borderTopRightRadius: theme.radius.large / 2,
        },
    shadow: {
      shadowOffset: {
        width: 0,
        height: theme.normalize(24),
      },
      shadowOpacity: 0.2,
      shadowRadius: 16.0,
      elevation: 24,
      backgroundColor: theme.colors.transparent,
      height: theme.normalize(70),
    },
    logo: {
      justifyContent: "center",
    },
    box: {
      flexDirection: "row",
      minWidth: theme.layout.containerMaxWidth,
    },
    box2: {
      height: theme.layout.navBottomHeight,
      flexDirection: "row",
      width: Dimensions.get("window").width,
    },
    safeArea: {
      backgroundColor: "white",
    },
    slider: {
      position: "absolute",
      top: isTopBarMode ? undefined : 0,
      bottom: isTopBarMode ? 0 : undefined,
      left: theme.normalize(isTopBarMode ? 40 : 25),
      width: tabWidth - theme.normalize(isTopBarMode ? 80 : 50),
      height: theme.normalize(5),
      borderBottomLeftRadius: isTopBarMode ? 0 : 20,
      borderBottomRightRadius: isTopBarMode ? 0 : 20,
      borderTopLeftRadius: isTopBarMode ? 20 : 0,
      borderTopRightRadius: isTopBarMode ? 20 : 0,
      backgroundColor: theme.colors.rootMenuText,
    },
  });
