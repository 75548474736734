import React from "react";
import AntDesignIcon from "@expo/vector-icons/AntDesign";
import IoniconsIcon from "@expo/vector-icons/Ionicons";
import EntypoIcon from "@expo/vector-icons/Entypo";
import EvilIconsIcon from "@expo/vector-icons/EvilIcons";
import FeatherIcon from "@expo/vector-icons/Feather";
import FontAwesomeIcon from "@expo/vector-icons/FontAwesome";
import FontAwesomeIcon5 from "@expo/vector-icons/FontAwesome5";
import FontistoIcon from "@expo/vector-icons/Fontisto";
import FoundationIcon from "@expo/vector-icons/Foundation";
import MaterialIcon from "@expo/vector-icons/MaterialIcons";
import MaterialCommunityIcon from "@expo/vector-icons/MaterialCommunityIcons";
import { StyleProp, ViewStyle } from "react-native";
import { Types } from "../../../types";
import useTheme from "../../../theme/ThemeProvider";
import { ColorType, FontSizeType } from "theme/ThemeTypes";

type Props = {
  type?: Types["iconTypes"];
  name: string;
  customSize?: number;
  sizeName?: FontSizeType;
  colorName?: ColorType;
  style?: StyleProp<ViewStyle>;
  accessibilityLabel?: string;
};

export const Icon = (props: Props) => {
  const { theme } = useTheme();
  const {
    type,
    name,
    customSize,
    sizeName = "large",
    colorName = "dark",
    style,
    accessibilityLabel,
  } = props;
  switch (type) {
    case "MaterialCommunity":
      return (
        <MaterialCommunityIcon
          name={name as keyof typeof MaterialCommunityIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "AntDesign":
      return (
        <AntDesignIcon
          name={name as keyof typeof AntDesignIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "Entypo":
      return (
        <EntypoIcon
          name={name as keyof typeof EntypoIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
        />
      );
    case "EvilIcons":
      return (
        <EvilIconsIcon
          name={name as keyof typeof EvilIconsIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "Feather":
      return (
        <FeatherIcon
          name={name as keyof typeof FeatherIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
        />
      );
    case "FontAwesome":
      return (
        <FontAwesomeIcon
          name={name as keyof typeof FontAwesomeIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "FontAwesome5":
      return (
        <FontAwesomeIcon5
          name={name as keyof typeof FontAwesomeIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "Fontisto":
      return (
        <FontistoIcon
          name={name as keyof typeof FontistoIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "Foundation":
      return (
        <FoundationIcon
          name={name as keyof typeof FoundationIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    case "Material":
    case "MaterialIcons":
      return (
        <MaterialIcon
          name={name as keyof typeof MaterialIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
        />
      );
    default:
      return (
        <IoniconsIcon
          name={name as keyof typeof IoniconsIcon.glyphMap}
          size={theme.normalize(customSize || theme.fontSizes[sizeName])}
          color={theme.colors[colorName]}
          style={style}
          aria-label={accessibilityLabel}
          accessibilityLabel={accessibilityLabel}
        />
      );
  }
};
