import React from "react";
import { QAndA } from "../../types/QAndA";
import { View } from "react-native";
import { Card, Clickable, Icon, JText } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./QAndAItemStyle";
import { JHtml } from "components/jlib/jHtml/JHtml";

type Props = {
  qAndA: QAndA;
  //siteName: string;
  setAnswerVisible: any;
  isOpen?: boolean;
};

export const QAndAItem: React.FC<Props> = ({
  qAndA,
  //siteName,
  setAnswerVisible,
  isOpen,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  const onToggle = () => {
    if (isOpen) {
      setAnswerVisible(null);
    } else {
      setAnswerVisible(qAndA.id);
    }
  };

  return (
    <Card style={styles.container} expandable ariaExpanded={isOpen}>
      <Clickable
        onPress={onToggle}
        style={styles.questionCard}
        ariaCurrent={isOpen}
      >
        <JText
          label={qAndA.question}
          isBold
          sizeName={"mediumLarge"}
          style={styles.text}
        />
        <Icon
          name={isOpen ? "chevron-up" : "chevron-down"}
          type="Entypo"
          colorName="grey"
          style={styles.arrowIcon}
        />

        {/* <JText
        label={siteName}
        isItalic
        sizeName={"mediumLarge"}
        style={styles.siteName}
  />*/}
      </Clickable>

      {isOpen && (
        <View style={styles.answerCard}>
          {qAndA.answerRich ? (
            <JHtml source={qAndA.answerRich || ""} style={styles.richText} />
          ) : (
            <JText
              label={qAndA.answer}
              sizeName={"mediumLarge"}
              style={styles.text}
            />
          )}
        </View>
      )}
    </Card>
  );
};
