import { BottomTabBarProps } from "@react-navigation/bottom-tabs";
import { useUser } from "hooks";
import React, { useCallback } from "react";
import { TabBar } from "../components";
import { ChatStackNavigator } from "./ChatStackNavigator";
import { ParamsStackNavigator } from "./ParamsStackNavigator";
import { PostsStackNavigator } from "./PostsStackNavigator";
import { SitesStackNavigator } from "./SitesStackNavigator";
import { FollowedSitesStackNavigator } from "./FollowedSitesStackNavigator";
import createBottomTabNavigator from "components/jlib/bottomBar/createBottomTabNavigator";

const Tab = createBottomTabNavigator();

export const BottomTabNavigator = ({ defaultHome }: any) => {
  const { checkNavShow } = useUser();

  const tabBar = useCallback(
    (props: BottomTabBarProps) => (
      <TabBar isShown={checkNavShow(props.navigation.getState())} {...props} />
    ),
    [checkNavShow]
  );

  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        unmountOnBlur: true,
        tabBarHideOnKeyboard: true,
        tabBarStyle: { display: "none" },
      }}
      tabBar={tabBar}
      initialRouteName={defaultHome}
    >
      <Tab.Screen name="news" component={PostsStackNavigator} />
      <Tab.Screen name="sites" component={SitesStackNavigator} />
      <Tab.Screen
        name="followedSites"
        component={FollowedSitesStackNavigator}
      />
      <Tab.Screen
        name="chat"
        component={ChatStackNavigator}
        /*options={{
          tabBarBadge: "5",
          tabBarBadgeStyle: {},
        }}*/
      />
      <Tab.Screen name="params" component={ParamsStackNavigator} />
    </Tab.Navigator>
  );
};
