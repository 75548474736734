import React, { useEffect, useState } from "react";
import fnStyles from "./SiteSearchFieldStyles";
import { useRef } from "react";
import * as Location from "expo-location";
import {
  Clickable,
  Icon,
  JText,
  TopSheet,
  TopSheetProps,
} from "components/jlib";
import useSites from "hooks/useSites";
import {
  Linking,
  Modal,
  Platform,
  View,
  useWindowDimensions,
} from "react-native";
import { isTopBarMode } from "components/utils/deviceMode";
import { REACT_APP_API_ENDPOINT } from "constants/Constants";
import {
  GooglePlacesAutocomplete,
  GooglePlacesAutocompleteRef,
} from "react-native-google-places-autocomplete";
import useTheme from "theme/ThemeProvider";
import { useLocation } from "hooks";

type Props = {
  panelOpen?: boolean;
};

export const SiteSearchField: React.FC<Props> = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { setFilters, filters, showFilters, setShowFilters } = useSites();
  const { askCoordinate, userLocationAllowed } = useLocation();
  const { width, height } = useWindowDimensions();
  const ref = useRef<GooglePlacesAutocompleteRef>(null);
  const topSheetRef = useRef<TopSheetProps>(null);
  const isTopBar = isTopBarMode({ width, height });
  const [isSettingsModalVisible, setIsSettingsModalVisible] = useState(false);

  useEffect(() => {
    if (showFilters) {
      topSheetRef?.current?.show();
    } else {
      topSheetRef?.current?.hide();
      (ref?.current as any)?.blur();
    }
  }, [showFilters]);

  return !isTopBar ? (
    <TopSheet
      ref={topSheetRef}
      onClose={() => {
        (ref?.current as any)?.blur();
        setShowFilters(false);
      }}
      onOpen={() => setShowFilters(true)}
    >
      <View style={styles.containerFilters}>
        <View style={styles.fieldMap}>
          <Icon type="IonIcons" name="map" colorName="grey" />
          <GooglePlacesAutocomplete
            placeholder={
              filters?.location?.name || "Trier par distance autour de ..."
            }
            // @ts-ignore
            ref={ref}
            fetchDetails
            requestUrl={{
              useOnPlatform: "all",
              url: `${REACT_APP_API_ENDPOINT}/sites`,
            }}
            enablePoweredByContainer={false}
            // @ts-ignore
            renderRightButton={(): React.ReactElement | null =>
              ref?.current?.getAddressText()?.length ? (
                <Clickable
                  style={styles.cross}
                  onPress={() => {
                    ref?.current?.setAddressText("");
                    ref?.current?.clear();
                    ref?.current?.focus();
                    setFilters(undefined);
                  }}
                  ariaLabel="Effacer"
                >
                  <Icon type="Entypo" name="cross" colorName="grey" />
                </Clickable>
              ) : null
            }
            onPress={(data, details = null) => {
              setFilters({
                ...filters,
                location: {
                  name: data.description,
                  lat: details?.geometry.location.lat,
                  lng: details?.geometry.location.lng,
                },
                goToUser: false,
              });
            }}
            textInputProps={{
              placeholderTextColor: filters?.location?.name
                ? theme.colors.dark
                : theme.colors.grey,
              selectionColor: theme.colors.dark,
              accessibilityLabel: "Trier par distance autour de...",
              title: "Trier par distance autour de...",
            }}
            query={{
              key:
                Platform.OS === "web"
                  ? "toto2"
                  : "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs",
              language: "fr",
              components: "country:fr",
            }}
            styles={{
              container: {
                height: theme.normalize(40),
              },
              textInputContainer:
                Platform.OS === "web"
                  ? styles.textInputContainerWeb
                  : styles.textInputContainer,
              textInput:
                Platform.OS === "web" ? styles.textInputWeb : styles.textInput,
              poweredContainer: {
                opacity: 0,
              },
              listView: {
                position: "absolute",
                top: theme.normalize(40),
                width: "100%",
              },
            }}
          />
          <Clickable
            onPress={async () => {
              if (await userLocationAllowed()) {
                await askCoordinate();
                const tmpFilters = { ...filters };
                if (tmpFilters?.location) {
                  delete tmpFilters.location;
                }

                setFilters({ ...tmpFilters, goToUser: true });
              } else {
                let { status } =
                  await Location.requestForegroundPermissionsAsync();
                if (status === "granted") {
                  const tmpFilters = { ...filters };
                  if (tmpFilters?.location) {
                    delete tmpFilters.location;
                  }

                  setFilters({ ...tmpFilters, goToUser: true });
                } else if (Platform.OS !== "web") {
                  setIsSettingsModalVisible(true);
                }
              }
            }}
            style={styles.inputButton}
            ariaLabel="Me géolocaliser"
          >
            <Icon
              type="MaterialCommunity"
              name="map-marker-radius"
              colorName="infoMedium"
              sizeName="largeHigh"
            />
          </Clickable>
        </View>
      </View>
      <Modal
        visible={isSettingsModalVisible}
        animationType="slide"
        transparent={true}
        onRequestClose={() => setIsSettingsModalVisible(false)}
      >
        <View style={styles.modalCenterContainer}>
          <View style={styles.modalView}>
            <JText
              label="L'accès à votre position est désactivé. Voulez-vous l'activer ?"
              centered
            />
            <View style={styles.modalRow}>
              <Clickable onPress={() => setIsSettingsModalVisible(false)}>
                <JText label="Annuler" isBold colorName="secondary" />
              </Clickable>
              <Clickable
                onPress={() => {
                  Linking.openSettings();
                  setIsSettingsModalVisible(false);
                }}
              >
                <JText label="Activer" isBold colorName="info" />
              </Clickable>
            </View>
          </View>
        </View>
      </Modal>
    </TopSheet>
  ) : (
    <View style={styles.fieldMapWeb}>
      <Icon type="IonIcons" name="map" colorName="grey" />
      <GooglePlacesAutocomplete
        placeholder={
          filters?.location?.name || "Trier par distance autour de ..."
        }
        // @ts-ignore
        ref={ref}
        fetchDetails
        requestUrl={{
          useOnPlatform: "all",
          url: `${REACT_APP_API_ENDPOINT}/sites`,
        }}
        enablePoweredByContainer={false}
        // @ts-ignore
        renderRightButton={(): React.ReactElement | null =>
          ref?.current?.getAddressText()?.length ? (
            <Clickable
              style={styles.cross}
              onPress={() => {
                ref?.current?.setAddressText("");
                ref?.current?.clear();
                ref?.current?.focus();
                setFilters(undefined);
              }}
              ariaLabel="Effacer"
            >
              <Icon type="Entypo" name="cross" colorName="grey" />
            </Clickable>
          ) : null
        }
        onPress={(data, details = null) => {
          setFilters({
            ...filters,
            location: {
              name: data.description,
              lat: details?.geometry.location.lat,
              lng: details?.geometry.location.lng,
            },
            goToUser: false,
          });
        }}
        textInputProps={{
          placeholderTextColor: filters?.location?.name
            ? theme.colors.dark
            : theme.colors.grey,
          selectionColor: theme.colors.dark,
        }}
        query={{
          key:
            Platform.OS === "web"
              ? "toto3"
              : "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs",
          language: "fr",
          components: "country:fr",
        }}
        styles={{
          container: {
            height: theme.normalize(40),
          },
          textInputContainer: {
            backgroundColor: theme.colors.white,
            height: theme.normalize(40),
            outlineStyle: "none",
          },
          textInput: {
            height: theme.normalize(40),
            backgroundColor: theme.colors.white,
            color: theme.colors.dark,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            outlineStyle: "none",
          },
          poweredContainer: {
            opacity: 0,
          },
          listView: {
            position: "absolute",
            top: theme.normalize(40),
            width: "100%",
          },
        }}
      />
      <Clickable
        onPress={async () => {
          if (await userLocationAllowed()) {
            await askCoordinate();
            const tmpFilters = { ...filters };
            if (tmpFilters?.location) {
              delete tmpFilters.location;
            }

            setFilters({ ...tmpFilters, goToUser: true });
          } else {
            let { status } = await Location.requestForegroundPermissionsAsync();
            if (status === "granted") {
              const tmpFilters = { ...filters };
              if (tmpFilters?.location) {
                delete tmpFilters.location;
              }

              setFilters({ ...tmpFilters, goToUser: true });
            } else if (Platform.OS !== "web") {
              setIsSettingsModalVisible(true);
            }
          }
        }}
        style={styles.inputButtonWeb}
        ariaLabel="Me géolocaliser"
      >
        <Icon
          type="MaterialCommunity"
          name="map-marker-radius"
          colorName="infoMedium"
          sizeName="largeHigh"
        />
      </Clickable>
    </View>
  );
};
