import React from "react";
import {
  Role,
  StyleProp,
  View,
  ViewStyle,
  useWindowDimensions,
} from "react-native";

import { isTopBarMode } from "components/utils/deviceMode";
import fnStyles from "./BoxedLayoutStyle";
import useTheme from "theme/ThemeProvider";
import { string } from "yup";

type Props = {
  style?: StyleProp<ViewStyle>;
  role?: "listitem" | "main" | "list" | "navigation";
  children: React.ReactNode[] | React.ReactNode;
  ariaLabel?: string;
};

export const BoxedLayout: React.FC<Props> = ({
  style,
  children,
  role,
  ariaLabel,
}) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  return (
    <View role={role} aria-label={ariaLabel} style={[styles.container, style]}>
      {children}
    </View>
  );
};
