import React from "react";
import { View, ImageBackground } from "react-native";
import { Button, JText, Spacer } from "../jlib";
import fnStyles from "./NoPostsStyle";
import { StackNavigationProp } from "@react-navigation/stack";
import { PostsParamsList, SitesParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { useTranslation } from "react-i18next";
import { useUser } from "hooks";
import useNotification from "providers/NotificationProvider";
import { bodyFocus } from "utils/bodyfocus";

type Props = {
  navigation:
    | StackNavigationProp<PostsParamsList, "postsHome">
    | StackNavigationProp<SitesParamsList, "sitePosts">;
  label?: string;
};

export const NoPosts: React.FC<Props> = ({ navigation, label }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();
  const { user } = useUser();
  const { pushToken } = useNotification();

  const displayNotification = !pushToken && !user?.id;

  return (
    <View style={styles.container}>
      <View style={styles.icon}>
        <ImageBackground
          source={theme.images.pictoClipboard}
          style={styles.image}
        />
        <JText label={label || t("post.noPost")} centered style={styles.text} />
        <JText
          labelKey="post.noPostNotifications"
          centered
          style={styles.text}
        />
      </View>
      <Spacer height="mediumLarge" backgroundColor={"transparent"} />
      {displayNotification && (
        <Button
          onPress={() => {
            // @ts-ignore
            navigation.navigate("params");
            bodyFocus();
          }}
          labelKey="post.activeNotifications"
          type={"gradient"}
        />
      )}
    </View>
  );
};
