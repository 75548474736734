import { useFocusEffect, useIsFocused } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import useSites from "hooks/useSites";
import React, { useCallback, useEffect, useMemo } from "react";
import { View } from "react-native";

import {
  LoadingScreen,
  NoSites,
  SimpleList,
  Spacer,
  SView,
  ThreadItem,
} from "../../components";
import { ChatParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { Site } from "../../types/Site";
import fnStyles from "./ChatSelectSiteStyles";
import { usePosts } from "hooks";
import { bodyFocus } from "utils/bodyfocus";

type Props = StackScreenProps<ChatParamsList, "chatSelectSite">;

export const ChatSelectSite: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  useSites();
  const styles = fnStyles(theme);
  const { sites, sortAndFilterSites, getSites, setSite, isFetching } =
    useSites();
  const { setPost } = usePosts();

  useFocusEffect(
    useCallback(() => {
      getSites();
    }, [getSites])
  );

  useEffect(() => {
    getSites();
  }, [getSites]);

  const onCardPressed = (site: Site) => {
    setSite(site);
    setPost(null);

    navigation.navigate("chatSelectType", {
      siteId: Number(site.id),
      postId: undefined,
    });
    bodyFocus();
  };

  const orderedItems = useMemo(
    () =>
      sortAndFilterSites(sites || [], {
        filterOnShownInApp: true,
        filterOnAllowThreads: true,
      }),
    [sites, sortAndFilterSites]
  );

  const spacer = useCallback(
    () => <Spacer height={"medium"} backgroundColor={"blurBackground"} />,
    []
  );
  return (
    <SView style={{ display: useIsFocused() ? "flex" : "none" }}>
      <View style={styles.container}>
        {isFetching && <LoadingScreen />}
        {(!sites || !sites.length || !orderedItems?.length) && <NoSites />}
        {!isFetching && sites && sites?.length > 0 && orderedItems?.length && (
          <SimpleList<Site>
            data={orderedItems}
            renderItem={({ item }: { item: Site }) => {
              return (
                <ThreadItem item={item} onPress={onCardPressed} isSite={true} />
              );
            }}
            keyExtractor={(item: Site) => item?.id?.toString()}
            ItemSeparatorComponent={spacer}
            contentContainerStyle={[
              {
                paddingTop: theme.normalize(20),
                paddingBottom: theme.normalize(150),
              },
            ]}
          />
        )}
      </View>
    </SView>
  );
};
