import { StackScreenProps } from "@react-navigation/stack";
import React, { useState } from "react";
import { View, useWindowDimensions } from "react-native";
import { NoQuestions, QAndAItem, Scrollable } from "../../components";
import { SitesParamsList } from "../../navigation";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import useSites from "hooks/useSites";
import useTheme from "../../theme/ThemeProvider";
import { QAndA } from "types/QAndA";
import { isTopBarMode } from "components/utils/deviceMode";
import { useIsFocused } from "@react-navigation/native";
import { bodyFocus } from "utils/bodyfocus";

type Props = StackScreenProps<SitesParamsList, "siteQuestions">;

export const SiteQuestionScreen: React.FC<Props> = ({ navigation }) => {
  const { site } = useSites();
  const { setSite } = useSites();
  const { theme } = useTheme();

  const [answerVisible, setAnswerVisible] = useState(null);

  const { width, height } = useWindowDimensions();

  if (!site) {
    return <View />;
  }

  const onChatNav = () => {
    setSite(site);
    navigation.navigate("chatSelectType", {
      postId: undefined,
      siteId: Number(site?.id),
    });
    bodyFocus();
  };

  return (
    <Scrollable
      additionalProps={{
        contentContainerStyle: {
          paddingTop: theme.normalize(110),
        },
      }}
      style={{ display: useIsFocused() ? "flex" : "none" }}
    >
      <BoxedLayout
        style={{
          backgroundColor: theme.colors.white,
          padding: isTopBarMode({ width, height })
            ? theme.sizings.medium
            : theme.normalize(theme.sizings.smallMedium),
          borderRadius: theme.radius.medium,
        }}
      >
        {site?.qAndAs?.length > 0 ? (
          site?.qAndAs.map((qAndA: QAndA) => {
            return (
              <View key={`qanda-${qAndA.id}`}>
                <QAndAItem
                  qAndA={qAndA}
                  //siteName={post.site?.name ?? ""}
                  isOpen={qAndA.id === answerVisible}
                  setAnswerVisible={setAnswerVisible}
                />
              </View>
            );
          })
        ) : (
          <View>
            <NoQuestions
              navigation={navigation}
              handleNav={onChatNav}
              site={site}
            />
          </View>
        )}
      </BoxedLayout>
    </Scrollable>
  );
};
