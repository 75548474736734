import { useFocusEffect } from "@react-navigation/native";
import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { useUser } from "hooks";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { FlatList, View } from "react-native";
import {
  BackgroundLayout,
  JText,
  NoSitesFollowed,
  SView,
  SiteCard,
  Spacer,
} from "../../components";
import { REACT_APP_ORGANIZATION_ID } from "../../constants";
import { useSites } from "../../hooks/useSites";
import { FollowedSitesParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { Site } from "../../types/Site";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { List } from "components/jlib/list/List";

type Props = StackScreenProps<FollowedSitesParamsList, "followedSitesHome">;

const spacer = () => (
  <Spacer height="smallMedium" backgroundColor="transparent" />
);

export const PostsFollowedSites: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();

  const appOrganizationId = REACT_APP_ORGANIZATION_ID;

  const {
    sites: items,
    refreshSites,
    isFetching,
    sortAndFilterSites,
  } = useSites();

  const { followedSites } = useUser();
  const { configuration } = useConfiguration();

  const [localfollowedSites, setlocalFollowedSites] = useState<Site[] | null>(
    null
  );

  useEffect(() => {
    refreshSites();
  }, [refreshSites]);

  useFocusEffect(
    useCallback(() => {
      setlocalFollowedSites(null);
    }, [])
  );

  useEffect(() => {
    if (!localfollowedSites && items?.length) {
      const sites = items.filter((item) =>
        followedSites?.find((id) => id === item.id)
      );
      setlocalFollowedSites(sites);
    }
  }, [items, localfollowedSites, followedSites, appOrganizationId]);

  let cat = null as any;

  const renderItem = (item: Site, index: number, nav: any) => {
    if (index === 0) {
      cat = null;
    }
    let displayCat = false;
    if (
      item?.siteType?.id !== cat?.id &&
      configuration.sites.display.typesSeparator
    ) {
      displayCat = true;
      cat = item.siteType;
    }

    return (
      <BoxedLayout>
        {displayCat && (
          <View style={{ padding: theme.sizings.medium }}>
            <JText labelKey={`siteTypes.${cat?.name}`} isBold />
          </View>
        )}
        <SiteCard key={item.id} item={item} navigation={nav} />
      </BoxedLayout>
    );
  };

  const orderedItems = useMemo(
    () => sortAndFilterSites(localfollowedSites || []),
    [localfollowedSites, sortAndFilterSites]
  );

  return (
    <BackgroundLayout>
      <SView>
        {orderedItems?.length === 0 && !isFetching ? (
          <BoxedLayout>
            <NoSitesFollowed navigation={navigation} />
          </BoxedLayout>
        ) : (
          <List
            data={orderedItems}
            renderItem={({ item, index }) =>
              renderItem(item, index, navigation)
            }
            contentContainerStyle={{
              marginTop: theme.normalize(30),
              paddingBottom: theme.normalize(240),
            }}
            ItemSeparatorComponent={spacer}
          />
        )}
      </SView>
    </BackgroundLayout>
  );
};
