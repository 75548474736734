import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  BackgroundLayout,
  Button,
  Card,
  JText,
  JTextInput,
  SView,
  Spacer,
} from "../../components";
import { Password } from "../../components/common/password";
import useUser from "../../hooks/useUser";
import { ParamsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./ChangePasswordScreenStyle";
import { View } from "react-native";
import { bodyFocus } from "utils/bodyfocus";

type Props = StackScreenProps<ParamsParamsList, "changePassword">;

export const ChangePasswordScreen: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);

  const [password, setPassword] = useState("");
  const [isPasswordValid, setIsPasswordValid] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [canChangePassword, setCanChangePassword] = useState(false);

  const { updateUser } = useUser();

  const onSubmit = async () => {
    const formatedData: any = {};

    formatedData.password = password;

    const res = await updateUser(formatedData);

    // TODO Alert if not working

    if (res) {
      navigation.navigate("paramsHome");
      bodyFocus();
    }
  };

  useEffect(() => {
    if (
      password.length > 0 &&
      password === confirmPassword &&
      isPasswordValid
    ) {
      setCanChangePassword(true);
    } else {
      setCanChangePassword(false);
    }
  }, [confirmPassword, password, isPasswordValid]);

  return (
    <BackgroundLayout>
      <SView>
        <BoxedLayout style={styles.contentContainer}>
          <Card>
            <JText
              labelKey="auth.changePassword"
              isBold
              centered
              titleLevel={2}
              titleLevelStyle={2}
            />

            <View
              style={{
                marginTop: theme.normalize(theme.sizings.large),
              }}
            >
              <Password
                name="password"
                value={password}
                onChangeValue={setPassword}
                setIsPasswordValid={setIsPasswordValid}
                isPasswordValid={isPasswordValid}
                label={t("auth.newPassword")}
              />
            </View>

            <View
              style={{
                marginTop: theme.normalize(theme.sizings.mediumLarge),
              }}
            >
              <JTextInput
                name="password-confirm"
                value={confirmPassword}
                onChangeValue={setConfirmPassword}
                inputType={"password"}
                icon="key"
                label={t("auth.confirmPassword")}
              />
            </View>

            <Spacer height="medium" backgroundColor={"transparent"} />

            {confirmPassword !== "" && password !== confirmPassword ? (
              <JText
                labelKey="auth.confirmPasswordError"
                colorName={"danger"}
                sizeName="mediumLarge"
              />
            ) : null}
            <Button
              type={"gradient"}
              labelKey="common.validate"
              onPress={onSubmit}
              containerStyle={styles.button}
              disabled={!canChangePassword}
            />
          </Card>
        </BoxedLayout>
      </SView>
    </BackgroundLayout>
  );
};
