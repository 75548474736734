import React, { useState } from "react";
import { Post } from "../../types/Post";
import {
  Card,
  Clickable,
  Column,
  Divider,
  Icon,
  JImage,
  JText,
  Tile,
} from "../jlib";
import {
  ActivityIndicator,
  Platform,
  View,
  useWindowDimensions,
} from "react-native";
import useTheme from "../../theme/ThemeProvider";
import { PostItemHeader } from "./PostItemHeader";
import fnStyles from "./PostItemStyle";
import dayjs from "dayjs";
import { usePosts } from "./../../hooks/usePosts";
import { useUser } from "hooks";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { isTopBarMode } from "components/utils/deviceMode";
import { JHtml } from "components/jlib/jHtml/JHtml";

type Props = {
  post: Post;
  postLevel?: number;
  onPress: (post: Post, firstFaq?: boolean) => void;
  onSitePressed?: (post: Post) => void;
};

export const PostItem: React.FC<Props> = ({ post, onPress, postLevel }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, width, isTopBarMode({ width, height }));
  const { onUpdateUpVoted } = usePosts();
  const { localHistoryThanksPosts } = useUser();

  const [isLikeUpdating, setIsLikeUpdating] = useState(false);

  const onLike = async () => {
    setIsLikeUpdating(true);
    await onUpdateUpVoted(post);
    setIsLikeUpdating(false);
  };

  const hasLiked = Boolean(
    localHistoryThanksPosts?.find(
      (postThanksId: any) => postThanksId === post?.id
    )
  );

  return (
    <BoxedLayout>
      <View style={styles.container}>
        <Card style={styles.card}>
          <Clickable onPress={() => onPress(post)}>
            <PostItemHeader post={post} level={postLevel} />
            {post?.mainImage && (
              <JImage
                source={post.mainImage.url}
                type={"Uri"}
                style={styles.mainImage}
              />
            )}

            <View style={styles.content}>
              <Column>
                {post.contentRich ? (
                  <JHtml
                    source={post.contentRich}
                    truncateLength={250}
                    truncateKeepBr
                    //    contentWidth={width}
                  />
                ) : (
                  <JText
                    label={post.content}
                    sizeName="mediumLarge"
                    numberOfLines={4}
                  />
                )}

                <JText
                  labelKey="post.readMore"
                  style={styles.more}
                  isBold
                  sizeName={"mediumLarge"}
                />
              </Column>
            </View>
          </Clickable>
          <View style={styles.content}>
            <Divider />
            <Column>
              <View style={styles.actions}>
                {post.publishedDate ? (
                  <View style={styles.time}>
                    <Icon
                      name={"clock"}
                      type={"MaterialCommunity"}
                      colorName={"info"}
                    />
                    <JText
                      label={dayjs(post.publishedDate).locale("fr").fromNow()}
                      fontWeight={"300"}
                      sizeName={"mediumLarge"}
                      isItalic
                      style={styles.timeLabel}
                    />
                  </View>
                ) : (
                  <View />
                )}

                <View style={styles.actionsContainer}>
                  {(post.thanks || 0) > 0 && (
                    <JText
                      label={`${post.thanks}`}
                      fontWeight={"300"}
                      sizeName={"mediumLarge"}
                      style={styles.thanksCount}
                    />
                  )}

                  <Clickable
                    onPress={onLike}
                    style={[hasLiked ? styles.downThanks : styles.upThanks]}
                    role={"button"}
                    ariaCurrent={hasLiked}
                    ariaPressed={hasLiked}
                    ariaLabel={"J'aime"}
                  >
                    {Platform.OS === "web" ? (
                      <span className="sr-only">J'aime</span>
                    ) : null}
                    {isLikeUpdating ? (
                      <ActivityIndicator
                        size="small"
                        color={theme.colors.info}
                        style={styles.likeSpinner}
                      />
                    ) : (
                      <Icon
                        name={hasLiked ? "like1" : "like2"}
                        type={"AntDesign"}
                        colorName={hasLiked ? "white" : "infoMedium"}
                      />
                    )}
                  </Clickable>

                  {post?.qAndAs?.length > 0 && (
                    <Tile
                      iconType={"MaterialCommunity"}
                      iconName={"message-reply-outline"}
                      iconSizeName={"mediumLarge"}
                      color="primary"
                      iconColorName="primaryDarker"
                      label={`${post.qAndAs?.length || 0}`}
                      labelStyle={styles.tile}
                      onPress={() => onPress(post, true)}
                    />
                  )}
                </View>
              </View>
            </Column>
          </View>
        </Card>
      </View>
    </BoxedLayout>
  );
};
