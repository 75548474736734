import { StackScreenProps } from "@react-navigation/stack";
import React, { useCallback, useEffect } from "react";
import { Platform, View } from "react-native";
import {
  BackgroundLayout,
  ForegroundButton,
  JText,
  TopNavigationBar,
} from "../../components";
import { ChatParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./../request/ReactScreenStyle";
import { useLocation, usePosts } from "hooks";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { useTranslation } from "react-i18next";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { ChatSelectSite } from "./ChatSelectSite";
import { ChatSelectPlace } from "./ChatSelectPlace";
import { REACT_APP_API_ENDPOINT, SPECIFIC_THEME } from "constants";
import useSites from "hooks/useSites";
import { GMapLocationSearchBar } from "components/jlib/GMapLocationSearchBar";
import { bodyFocus } from "utils/bodyfocus";

type Props = StackScreenProps<ChatParamsList, "chatSelectSite">;

const Tab = createMaterialTopTabNavigator<ChatParamsList>();

const Header = () => {
  const { configuration } = useConfiguration();
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.header}>
      <JText
        labelKey={
          SPECIFIC_THEME === "parlonstravaux"
            ? "chat.choseSite"
            : "chat.choseRecipient"
        }
        isBold
        centered
        titleLevel={2}
        style={styles.headerMainTilte}
      />
      {configuration &&
        configuration.threads.features.enableThreadWithoutSite && (
          <JText
            labelKey="common.optional"
            centered
            style={styles.headerMainTilte}
          />
        )}
    </View>
  );
};

const SearchBar = () => {
  const { setFilters, filters } = useSites();
  const { askCoordinate, userLocationAllowed, locationData } = useLocation();

  return (
    <GMapLocationSearchBar
      requestUrl={`${REACT_APP_API_ENDPOINT}/sites`}
      queryKey={
        Platform.OS === "web" ? "" : "AIzaSyBoabCFYUx-SQW3QtY0WmkRRuzsfhmQtCs"
      }
      renderRightButton
      onPressRightButton={() => {
        setFilters(undefined);
      }}
      onPress={(data, details = null) => {
        setFilters({
          ...filters,
          location: {
            name: data.description,
            lat: details?.geometry.location.lat,
            lng: details?.geometry.location.lng,
          },
        });
      }}
      renderRightPositionButton
      onPressRenderRightPositionButton={async () => {
        if (await userLocationAllowed()) {
          await askCoordinate();
          const tmpFilters = { ...filters };
          if (tmpFilters?.location) {
            delete tmpFilters.location;
          }
          setFilters({
            ...tmpFilters,
            location: {
              name: locationData.label,
              lat: locationData.lat,
              lng: locationData.lng,
            },
            goToUser: true,
          });
        }
      }}
      styles={{
        width: "100%",
      }}
    />
  );
};

export const ChatSelectSiteOrPlace: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const styles = fnStyles(theme);
  const { configuration } = useConfiguration();
  const { setPost } = usePosts();
  const { setSite } = useSites();

  const onSkipSitePressed = () => {
    setSite(undefined);
    setPost(null);

    navigation.navigate("chatSelectType", {
      siteId: undefined,
      postId: undefined,
    });
    bodyFocus();
  };

  const tabBar = useCallback(
    (props: any) => (
      <TopNavigationBar
        {...props}
        low
        TopComponent={Header}
        BottomComponent={SearchBar}
      />
    ),
    []
  );

  const { setFilters } = useSites();

  useEffect(() => {
    setFilters(undefined);
  }, [setFilters]);

  return (
    <BackgroundLayout>
      <View style={styles.contentContainer}>
        {configuration && (
          <Tab.Navigator
            initialRouteName={"chatSelectSite"}
            screenOptions={() => ({
              tabBarScrollEnabled: false,
              swipeEnabled: false,
              title: `${t("header.selectSite")} | ${theme.texts.appName}`,
              headerTitle: t("header.selectSite"),
            })}
            sceneContainerStyle={{ backgroundColor: "transparent" }}
            tabBar={tabBar}
          >
            <Tab.Screen name={"chatSelectSite"} component={ChatSelectSite} />

            {!!configuration?.threads?.features?.enableThreadWithLocation && (
              <Tab.Screen
                name={"chatSelectPlace"}
                component={ChatSelectPlace}
              />
            )}
          </Tab.Navigator>
        )}
      </View>
      {configuration &&
        configuration?.threads?.features?.enableThreadWithoutSite && (
          <ForegroundButton
            handleButtonPressed={onSkipSitePressed}
            buttonLabel={t("chat.ignoreAndContinueButton")}
            iconName="keyboard-arrow-right"
            iconType="MaterialIcons"
            bottomBar={false}
            spanHorizontal
          />
        )}
    </BackgroundLayout>
  );
};
