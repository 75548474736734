import { isTopBarMode } from "../../../components/utils/deviceMode";
import React from "react";
import { Platform, View, useWindowDimensions } from "react-native";
import { Button, Clickable, Icon, JText, Row } from "../../../components";
import useUser from "../../../hooks/useUser";
import useTheme from "../../../theme/ThemeProvider";
import fnStyles from "./../ParamsScreenStyle";
import { useTranslation } from "react-i18next";
import useConfiguration from "../../../configuration/ConfigurationProvider";
import { bodyFocus } from "utils/bodyfocus";

const AccountInfosBlock = ({ navigation }: any) => {
  const { theme } = useTheme();
  const { t } = useTranslation();
  const { width, height } = useWindowDimensions();
  const { configuration } = useConfiguration();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));

  const { user, enableSmsNotifications } = useUser();

  const showMobileField =
    enableSmsNotifications || configuration.profile.phone.share;

  const onChangePasswordButtonPressed = () => {
    navigation.navigate("changePassword");
    bodyFocus();
  };

  const onChangeInfosButtonPressed = () => {
    navigation.navigate("changeInfos");
    bodyFocus();
  };

  return (
    <View>
      <JText
        labelKey="auth.myInfosTitle"
        isBold
        centered
        style={styles.notificationSectionLabel}
        titleLevel={2}
      />
      <Clickable
        onPress={onChangeInfosButtonPressed}
        style={styles.editMyAccount}
        ariaLabel="Modifier"
      >
        <Icon
          name={"edit"}
          type={"Entypo"}
          colorName={"infoMedium"}
          sizeName={"large"}
        />
      </Clickable>
      <View role="list">
        <Row
          role="listitem"
          align={"center"}
          justify="center"
          style={{
            marginVertical: theme.sizings.tiny,
            alignSelf: "center",
            width: width > 767 ? "50%" : "75%",
          }}
        >
          <View style={styles.pin}>
            <Icon
              name={"user"}
              type="FontAwesome"
              colorName={"infoMedium"}
              accessibilityLabel="Nom d'utilisateur"
            />
          </View>

          <JText
            label={user?.name}
            style={styles.textInfos}
            sizeName="mediumLarge"
          />
        </Row>

        <Row
          role="listitem"
          align={"center"}
          justify="center"
          style={{
            marginVertical: theme.sizings.tiny,
            alignSelf: "center",
            width: width > 767 ? "50%" : "75%",
          }}
        >
          <View style={styles.pin}>
            <Icon
              name={"envelope"}
              type="FontAwesome"
              colorName={"infoMedium"}
              accessibilityLabel="Email"
            />
          </View>
          <JText
            label={user?.email}
            style={styles.textInfos}
            sizeName="mediumLarge"
          />
        </Row>

        {showMobileField && (
          <Row
            role="listitem"
            align={"center"}
            justify="center"
            style={{
              marginVertical: theme.sizings.tiny,
              alignSelf: "center",
              width: width > 767 ? "50%" : "75%",
            }}
          >
            <View style={styles.pin}>
              <Icon
                name={"mobile"}
                type="FontAwesome"
                colorName={"infoMedium"}
                sizeName="larger"
                accessibilityLabel="Téléphone"
              />
            </View>
            <JText
              label={
                user?.phoneNumber?.length
                  ? user?.phoneNumber
                  : t("auth.noPhone")
              }
              style={styles.textInfos}
              sizeName="mediumLarge"
            />
          </Row>
        )}

        {configuration &&
          configuration?.profile?.features?.hasUserType &&
          user?.userType && (
            <Row
              align={"center"}
              role="listitem"
              justify="center"
              style={{
                marginVertical: theme.sizings.tiny,
                alignSelf: "center",
                width: width > 767 ? "50%" : "75%",
              }}
            >
              <View style={styles.pin}>
                <Icon
                  name={"user-circle-o"}
                  type="FontAwesome"
                  colorName={"infoMedium"}
                  sizeName="large"
                  accessibilityLabel="Type de compte"
                />
              </View>
              <JText
                label={
                  user?.userType ? user?.userType?.name : t("auth.noPhone")
                }
                style={styles.textInfos}
                sizeName="mediumLarge"
              />
            </Row>
          )}
      </View>

      <Button
        type={"gradient"}
        labelKey="auth.changeMyPassword"
        onPress={onChangePasswordButtonPressed}
        containerStyle={styles.newButton}
        iconName="key"
      />
    </View>
  );
};

export default AccountInfosBlock;
