import React from "react";
import { View, ImageBackground } from "react-native";
import { Button, JText, Spacer } from "../jlib";
import fnStyles from "./NoSitesFollowedStyle";
import { StackNavigationProp } from "@react-navigation/stack";
import { PostsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import getWording from "../../utils/wording";
import { useTranslation } from "react-i18next";
import useConfiguration from "../../configuration/ConfigurationProvider";
import { bodyFocus } from "utils/bodyfocus";

type Props = {
  navigation: StackNavigationProp<PostsParamsList, "postsHome">;
};

export const NoSitesFollowed: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { t } = useTranslation();

  const { configuration } = useConfiguration();
  return (
    <View style={styles.container}>
      <ImageBackground
        source={theme.images.pictoSearch}
        style={{
          height: theme.normalize(120),
          width: theme.normalize(120),
        }}
      />

      <Spacer height="high" backgroundColor={"transparent"} />

      <JText
        label={t("site.noFollowed", {
          wording: getWording(configuration).plural,
        })}
        centered
        style={styles.text}
      />

      <Spacer height="mediumLarge" backgroundColor={"transparent"} />

      <Button
        onPress={() => {
          // @ts-ignore
          navigation.navigate("sites");
          bodyFocus();
        }}
        label={t("site.search", {
          wording: getWording(configuration).plural,
        })}
        type={"gradient"}
      />
    </View>
  );
};

export const NoSites = () => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <JText labelKey="site.noSiteToContact" centered style={styles.text} />
    </View>
  );
};
