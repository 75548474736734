import React from "react";
import { Platform, StyleProp, View, ViewStyle } from "react-native";
import useTheme from "../../../theme/ThemeProvider";
import { Icon } from "../../jlib";
import fnStyles from "./DifferentDayDateStyle";
import { DateItem } from "./DateItem";

type Props = {
  startDate?: Date;
  endDate?: Date;
  type: "classic" | "large";
  hideHours?: boolean;
  style?: StyleProp<ViewStyle>;
};

export const DifferentDayDateDisplay: React.FC<Props> = ({
  startDate,
  endDate,
  type = "classic",
  hideHours = false,
  style,
}) => {
  const { theme } = useTheme();
  const isLarge = type === "large";
  const styles = fnStyles(theme, isLarge);

  return (
    <View style={[styles.container, style]}>
      {startDate && (
        <DateItem date={startDate} type={type} hideHours={hideHours} />
      )}
      {startDate && endDate && (
        <>
          {Platform.OS === "web" ? (
            <span className="sr-only">jusqu'au</span>
          ) : null}
          <Icon
            type={"Entypo"}
            name={"chevron-right"}
            colorName={"greyDark"}
            style={{ alignSelf: "center" }}
          />
        </>
      )}
      {endDate && <DateItem date={endDate} type={type} hideHours={hideHours} />}
    </View>
  );
};
