import React from "react";
import { View } from "react-native";

type Props = {
  data: any[];
  renderItem: ({ item, index }: { item: any; index: number }) => JSX.Element;
  ItemSeparatorComponent?: JSX.ElementType;
  contentContainerStyle?: object;
};

export const List = ({
  data,
  renderItem,
  ItemSeparatorComponent,
  contentContainerStyle,
}: Props) => {
  return (
    <View role="list" style={contentContainerStyle}>
      {data.map((item, index) => {
        return (
          <View role="listitem" key={index}>
            {renderItem({ item, index })}
            {ItemSeparatorComponent && <ItemSeparatorComponent />}
          </View>
        );
      })}
    </View>
  );
};

export default List;
