import {
  Roboto_100Thin,
  Roboto_100Thin_Italic,
  Roboto_300Light,
  Roboto_300Light_Italic,
  Roboto_400Regular,
  Roboto_400Regular_Italic,
  Roboto_500Medium,
  Roboto_500Medium_Italic,
  Roboto_700Bold,
  Roboto_700Bold_Italic,
  Roboto_900Black,
  Roboto_900Black_Italic,
} from "@expo-google-fonts/roboto";
import {
  NavigationContainer,
  useNavigationContainerRef,
} from "@react-navigation/native";
import { useFonts } from "expo-font";
import * as Linking from "expo-linking";
import React from "react";
import "react-native-get-random-values";
import { SafeAreaProvider } from "react-native-safe-area-context";
import "./i18n";

import dayjs from "dayjs";
import Toast, {
  ErrorToast,
  InfoToast,
  SuccessToast,
} from "react-native-toast-message";
import * as SplashScreen from "expo-splash-screen";
import { RootStack } from "./src";
import AppProvider from "./src/AppProvider";

const relativeTime = require("dayjs/plugin/relativeTime");
require("dayjs/locale/fr");

import { StatusBar } from "expo-status-bar";
import { Platform } from "react-native";

/*
const routingInstrumentation =
  new Sentry.Native.ReactNavigationInstrumentation();
*/

// Sentry.init({
//   dsn: "https://bf5444835e53e2c77f9f5dcc9f348baf@o432100.ingest.sentry.io/4506156644892672",
//   // Set tracesSampleRate to 1.0 to capture 100% of transactions for performance monitoring.
//   // We recommend adjusting this value in production.
//   tracesSampleRate: 0.5,
//   enableInExpoDevelopment: true,
//   debug: false, // If `true`, Sentry will try to print out useful debugging information if something goes wrong with sending the event. Set it to `false` in production
//   /*integrations: [
//     new Sentry.Native.ReactNativeTracing({
//       // Pass instrumentation to be used as `routingInstrumentation`
//       routingInstrumentation,
//       // ...
//     }),
//   ],*/
// });

// Keep the splash screen visible while we fetch resources
if (Platform.OS !== "web") {
  SplashScreen.preventAutoHideAsync();
}

const App = () => {
  const navigation = useNavigationContainerRef();

  let [fontLoaded] = useFonts({
    Roboto_100Thin,
    Roboto_100Thin_Italic,
    Roboto_300Light,
    Roboto_300Light_Italic,
    Roboto_400Regular,
    Roboto_400Regular_Italic,
    Roboto_500Medium,
    Roboto_500Medium_Italic,
    Roboto_700Bold,
    Roboto_700Bold_Italic,
    Roboto_900Black,
    Roboto_900Black_Italic,
  });

  if (!fontLoaded) {
    return null; // TODO: show loading screen
  }
  dayjs.extend(relativeTime);
  dayjs.locale("fr");

  const prefix = Linking.createURL("/");

  const linking = {
    prefixes: [
      prefix,
      "https://app.informez-moi.fr",
      "https://frontend-develop.informez-moi.fr/",
    ],
    config: {
      screens: {
        chat: {
          path: "messages",
          initialRouteName: "chatHome",
          screens: {
            chatHome: "",
            chatSelectSiteOrPlace: {
              path: "selectsiteorplace",
            },
            chatSelectPlace: {
              path: "selectplace",
            },
            chatSelectSite: {
              path: "selectsite",
            },
            chatSelectType: {
              path: "selectmessagetype",
              parse: {
                postId: Number,
                siteId: Number,
              },
            },
            chatSelectUserType: {
              path: "selectusertype",
              parse: {
                postId: Number,
                siteId: Number,
                threadId: Number,
                threadTypeId: Number,
              },
            },
            reactMessage: {
              path: "message/:threadId?",
              parse: {
                threadId: Number,
                threadTypeId: Number,
                userType: String,
                postId: Number,
                siteId: Number,
              },
            },
            contactProfile: {
              path: "message/:threadId?/contact/:userId?",
              parse: {
                threadId: Number,
                userId: Number,
              },
            },
          },
        },
        params: {
          path: "parametres",
          initialRouteName: "paramsHome",
          screens: {
            paramsHome: "",
            changeInfos: "update-profile",
            changePassword: "update-password",
            signUp: "signup",
            logIn: "login",
            resetPasswordEmail: "reset-password-request",
            resetPassword: "reset-password",
            resetPasswordEmailCheck: "check-your-email",
            validateAccount: "validate-account",
          },
        },
        news: {
          path: "news",
          initialRouteName: "postsHome",
          screens: {
            postsHome: "",
            postDetails: {
              path: "article/:postId",
              parse: {
                postId: Number,
              },
              screens: {
                postInformation: {
                  path: "info",
                },
                postQuestions: {
                  path: "faq",
                },
              },
            },
            siteDetails: {
              path: "site/:siteId",
              parse: {
                siteId: Number,
              },
              screens: {
                siteInformation: {
                  path: "info",
                },
                sitePosts: {
                  path: "news",
                },
                siteRoadmap: {
                  path: "roadmap",
                },
                siteQuestions: {
                  path: "faq",
                },
              },
            },
          },
        },
        followedSites: {
          path: "followedSites",
          initialRouteName: "followedSitesHome",
          screens: {
            followedSitesHome: {
              path: "",
            },
            siteDetails: {
              path: "site/:siteId",
              parse: {
                siteId: Number,
              },
              screens: {
                siteInformation: {
                  path: "info",
                },
                sitePosts: {
                  path: "news",
                },
                siteRoadmap: {
                  path: "roadmap",
                },
                siteQuestions: {
                  path: "faq",
                },
              },
            },
            chatSelectType: {
              path: "selectmessagetype",
              parse: {
                postId: Number,
                siteId: Number,
              },
            },
            postDetails: {
              initialRouteName: "siteHome",
              path: "article/:postId",
              parse: {
                postId: Number,
              },
              screens: {
                postInformation: {
                  path: "info",
                },
                postQuestions: {
                  path: "faq",
                },
              },
            },
          },
        },
        sites: {
          path: "sites",
          initialRouteName: "siteHome",
          screens: {
            siteHome: {
              path: "",
              screens: {
                siteList: "list",
                siteMap: "map",
              },
            },
            siteSuggestion: {
              path: "suggestion",
            },
            siteDetails: {
              path: "site/:siteId",
              parse: {
                siteId: Number,
              },
              screens: {
                siteInformation: {
                  path: "info",
                },
                sitePosts: {
                  path: "news",
                },
                siteRoadmap: {
                  path: "roadmap",
                },
                siteQuestions: {
                  path: "faq",
                },
              },
            },
            postDetails: {
              initialRouteName: "siteHome",
              path: "article/:postId",
              parse: {
                postId: Number,
              },
              screens: {
                postInformation: {
                  path: "info",
                },
                postQuestions: {
                  path: "faq",
                },
              },
            },
          },
        },
      },
    },
  };

  return (
    <>
      <SafeAreaProvider>
        <NavigationContainer
          linking={linking}
          ref={navigation}
          /* onReady={() => {
          // Register the navigation container with the instrumentation
          routingInstrumentation.registerNavigationContainer(navigation);
        }}*/
        >
          <AppProvider>
            <RootStack />
          </AppProvider>
          <Toast
            config={{
              success: (props) => {
                return props.isVisible ? <SuccessToast {...props} /> : null;
              },
              error: (props) => {
                return props.isVisible ? <ErrorToast {...props} /> : null;
              },
              info: (props) => {
                return props.isVisible ? <InfoToast {...props} /> : null;
              },
            }}
          />
        </NavigationContainer>
      </SafeAreaProvider>
      <StatusBar style="light" translucent={true} />
    </>
  );
};

// export default Sentry.Native.wrap(App);
export default App;
