import React from "react";
import { Post } from "../../types/Post";
import { View } from "react-native";
import { Icon, JImage, JText } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
import { DateDisplay, Notch } from "../common";
import fnStyles from "./PostItemHeaderStyle";
import BadgeDemo from "components/common/badgeDemo.tsx/BadgeDemo";
import { useTranslation } from "react-i18next";

type Props = {
  post: Post;
  level?: number;
};

export const PostItemHeader: React.FC<Props> = ({ post, level = 2 }) => {
  const { theme } = useTheme();
  const { t } = useTranslation();

  const { color, imageLightMode } = post?.type;

  const styles = fnStyles(theme, color || "");
  const hasDate = post.type.shouldHavePlanning && post.startEventDate;

  return (
    <View style={styles.container}>
      <Notch color={color} />
      {post.site?.isDraft && <BadgeDemo />}
      {post.pinned && (
        <View style={styles.pinnedContainer}>
          <Icon
            name="pin"
            type="MaterialCommunity"
            sizeName="mediumLarge"
            colorName="greyDark"
          />
          <JText
            label="Épinglé"
            sizeName="average"
            style={styles.pinnedText}
            colorName="greyDark"
          />
        </View>
      )}
      <View style={styles.rowContainer}>
        {(post.site?.logo || post.site?.mainImage) && (
          <JImage
            source={
              post.site.logo
                ? post.site.logo.url
                : post.site.mainImage?.url ?? ""
            }
            type={"Uri"}
            style={styles.siteImage}
          />
        )}
        <View style={styles.titleBox}>
          <JText
            label={post.site?.name}
            sizeName={"largeLow"}
            //numberOfLines={1}
            style={hasDate && styles.beforeDateText}
          />
        </View>
        <View style={{ alignItems: "center" }}>
          {hasDate && (
            <View style={{ marginBottom: 10 }}>
              <DateDisplay
                startDate={post.startEventDate}
                endDate={post.endEventDate}
                hideHours
              />
            </View>
          )}
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            {post.type.shouldHavePerturbation && post.alertType?.icon ? (
              <>
                <View style={[styles.postIcon]}>
                  <JImage
                    source={post.alertType.icon?.url || ""}
                    type={"Uri"}
                    style={styles.postIconInner}
                    alt=""
                  />
                </View>
                <JText sizeName="mediumLarge">{post.alertType?.name}</JText>
              </>
            ) : (
              <View style={[styles.postIcon]}>
                <JImage
                  source={imageLightMode?.url || ""}
                  type={"Uri"}
                  style={styles.postIconInner}
                />
              </View>
            )}
          </View>
        </View>
      </View>
      <View style={styles.rowContainer}>
        <View style={[styles.row, hasDate && styles.beforeDateRow]}>
          <View style={styles.titleBox}>
            <JText
              label={post.title}
              isBold
              sizeName={"largeLow"}
              style={hasDate && styles.beforeDateText}
              titleLevel={level}
              titleLevelStyle={3}
            />
          </View>
        </View>
      </View>
    </View>
  );
};
