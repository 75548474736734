import { StackScreenProps } from "@react-navigation/stack";
import { BoxedLayout } from "components/common/layout/BoxedLayout";
import { DocumentModal } from "components/modals/documentModal";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ActivityIndicator,
  Image,
  Keyboard,
  Platform,
  StyleSheet,
  Text,
  TouchableOpacity,
  TouchableWithoutFeedback,
  useWindowDimensions,
  View,
} from "react-native";
import {
  GiftedChat,
  IMessage,
  InputToolbar,
  Message,
  Send,
} from "react-native-gifted-chat";
import {
  BackgroundLayout,
  Icon,
  JImage,
  JText,
  Row,
  Stack,
} from "../../components";
import useThread from "../../hooks/useThread";
import useUser from "../../hooks/useUser";
import useTheme from "../../theme/ThemeProvider";
import { convertMessages, fetchJSON } from "../../utils";
import { downloadFile, getDocumentType } from "../../utils/fileUtilities";
import { uploadImages } from "../../utils/uploadImage";
import { ReactParamsList } from "./ReactParamsList";
import fnStyles from "./ReactScreenStyle";
import * as Notifications from "expo-notifications";

type Props = StackScreenProps<ReactParamsList, "reactMessage">;

export type JImage = {
  uri: string;
};

import "dayjs/locale/fr";
import useSites from "hooks/useSites";
import { hashCode } from "utils/hash";
import { usePosts } from "../../hooks/usePosts";
import { useTranslation } from "react-i18next";
import JImagePicker from "components/jlib/imagePicker/JImagePicker";
import { isTopBarMode } from "components/utils/deviceMode";
import dayjs from "dayjs";
import { bodyFocus } from "utils/bodyfocus";
import CustomTag from "components/common/customtag/Customtag";

function isSameDay(
  currentMessage: IMessage,
  diffMessage: IMessage | null | undefined
) {
  if (!diffMessage || !diffMessage.createdAt) return false;

  const currentCreatedAt = dayjs(currentMessage.createdAt);
  const diffCreatedAt = dayjs(diffMessage.createdAt);

  if (!currentCreatedAt.isValid() || !diffCreatedAt.isValid()) return false;

  return currentCreatedAt.isSame(diffCreatedAt, "day");
}

const dayStyles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    marginTop: 5,
    marginBottom: 10,
  },
  text: {
    backgroundColor: "transparent",
    color: "#b2b2b2",
    fontSize: 12,
    fontWeight: "600",
  },
});

export const ReactMessageScreen: React.FC<Props> = ({ route, navigation }) => {
  const { theme } = useTheme();
  const { width, height } = useWindowDimensions();
  const styles = fnStyles(theme, isTopBarMode({ width, height }));
  const { t } = useTranslation();

  const { user, token } = useUser();
  const { site, fetchSite } = useSites();

  const { post, fetchPost } = usePosts();
  const { thread, getThreadType, fetchThread } = useThread();

  const [selectedImages, setSelectedImages] = useState<JImage[]>([]);

  const [currentZoomedImage, setCurrentZoomedImage] = useState("");
  const [isSending, setIsSending] = useState(false);

  useEffect(() => {
    if (route.params.siteId && (!site || site.id !== route.params.siteId)) {
      fetchSite(route.params.siteId);
    }
  }, [route.params.siteId, site, fetchSite]);

  useEffect(() => {
    if (route.params.postId && (!post || post.id !== route.params.postId)) {
      fetchPost(route.params.postId);
    }
  }, [route.params.postId, post, fetchPost]);

  const threadType = route.params.threadTypeId
    ? getThreadType(route.params.threadTypeId)
    : null;

  const userType = route.params.userType;

  useEffect(() => {
    if (
      route.params.threadId &&
      (!thread || thread.id !== route.params.threadId)
    ) {
      fetchThread(route.params.threadId);
    }
  }, [route.params.threadId, thread, fetchThread]);

  const messages: IMessage[] = useMemo(
    () => convertMessages(thread?.messages || []),
    [thread]
  );

  const renderImageItem = (currentFile: string) => {
    return (
      <TouchableOpacity
        key={hashCode(currentFile)}
        onPress={() => setCurrentZoomedImage(currentFile)}
        role="button"
        aria-label={t("titles.imageInModalOpen")}
        aria-haspopup="dialog"
      >
        <JImage
          source={currentFile}
          style={styles.imageInChat}
          resizeMode={"contain"}
          alt={t("titles.imageInChatUntitled")}
        />
      </TouchableOpacity>
    );
  };

  const renderDocumentItem = (currentFile: any) => {
    return (
      <TouchableOpacity
        onPress={() => downloadFile(currentFile)}
        style={styles.documentInChat}
        role="button"
        aria-label={t("labels.downloadDocument")}
      >
        <Icon
          name="file-pdf-o"
          type="FontAwesome"
          colorName="white"
          sizeName="largeHigh"
          style={styles.documentIcon}
        />
        <JText
          label={t("chat.loadDoc", {
            type: currentFile.substr(currentFile.length - 4),
          })}
          colorName="white"
          sizeName="medium"
        />
      </TouchableOpacity>
    );
  };

  const renderItem = (currentFile: string) => {
    if (
      getDocumentType(currentFile.substring(currentFile.length - 4)) === "Image"
    ) {
      return renderImageItem(currentFile);
    } else {
      return renderDocumentItem(currentFile);
    }
  };

  const sendMessages = useCallback(
    async (message: IMessage) => {
      const res = await fetchJSON({
        url: "messages/add",
        method: "POST",
        jwt: token,
        payload: {
          data: {
            thread: {
              id: thread?.id,
              site: site?.id,
              post: post?.id,
              author: message.user._id,
              type: threadType?.id,
              messages: [],
              status: "waiting_answer",
              isRead: false,
              userType: userType,
              geojson:
                route.params.lat && route.params.lng
                  ? {
                      lat: route.params.lat,
                      lng: route.params.lng,
                      label: route.params.address,
                    }
                  : null,
            },
            message: {
              content: message.text,
              author: message.user._id,
              documents: message.image,
              isInternal: false,
              upVotes: [],
              isRead: false,
              privateThreadIntern: [],
              responseOf: null,
              exifData: { data: message.exifData },
            },
          },
        },
      });

      return res;
    },
    [
      post?.id,
      route.params.lat,
      route.params.lng,
      route.params.address,
      site?.id,
      thread?.id,
      threadType?.id,
      token,
      userType,
    ]
  );

  const handleSendMessage = useCallback(
    async (msg = [] as IMessage[]) => {
      setIsSending(true);

      let exifData: any[] = [];
      if (selectedImages && selectedImages.length > 0) {
        const { images, exif } = await uploadImages(selectedImages);
        if (images) {
          msg[0].image = images;
          if (exif && exif.length > 0) exifData = exif;
        }
        msg[0].exifData = exifData;
      }

      const res = await sendMessages(msg[0]);

      if (res) {
        fetchThread(res.thread.id);
        setSelectedImages([]);

        if (!thread) {
          navigation?.reset({
            index: 0,
            routes: [
              {
                name: "chat",
                state: {
                  routes: [
                    { name: "chatHome" },
                    {
                      name: "reactMessage",
                      params: {
                        siteId: site?.id,
                        postId: post?.id,
                        threadId: res.thread.id,
                        threadTypeId: res.thread?.type?.id,
                        threadType: res.thread.type,
                      },
                    },
                  ],
                },
              },
            ],
          });
        }
      }
      setIsSending(false);
    },
    [
      sendMessages,
      selectedImages,
      fetchThread,
      thread,
      navigation,
      site?.id,
      post?.id,
    ]
  );

  const handleCancelImage = useCallback((imageIndex: number) => {
    setSelectedImages((previousImages) => {
      const prev = [...previousImages];
      prev.splice(imageIndex, 1);
      return prev;
    });
  }, []);

  const customtInputToolbar = (props: any) => {
    return (
      <InputToolbar
        {...props}
        containerStyle={[styles.inputStyle]}
        primaryStyle={[
          isTopBarMode({ width, height }) ? undefined : styles.textInputStyle,
          { borderWidth: 1.5, borderColor: "#444", backgroundColor: "#fefefe" },
        ]}
      />
    );
  };

  // C'est une version très simpliste de la chose
  // Il faudrait sûrement regarder si la notif reçue correspond bien à un message de la conversation actuelle
  useEffect(() => {
    const handleNotification = () => {
      if (thread) {
        fetchThread(thread.id);
      } else {
        fetchThread(route.params.threadId!);
      }
    };

    const notificationSubscription =
      Notifications.addNotificationReceivedListener(handleNotification);

    return () => {
      notificationSubscription.remove();
    };
  }, [fetchThread, route.params.threadId, thread]);

  const onAvatarPressed = (u: any) => {
    navigation.navigate("contactProfile", {
      threadId: route.params.threadId ?? thread?.id!,
      userId: u._id,
    });
    bodyFocus();
  };

  const renderChatContainer = (props) => (
    <View
      {...props}
      role="chat" // Adding role to the chat container
      style={{ flex: 1, backgroundColor: "#fff" }}
    >
      {props.children}
    </View>
  );

  return (
    <>
      <BackgroundLayout
        style={{
          marginTop: theme.normalize(-30),
          paddingTop: theme.normalize(30),
        }}
      >
        <CustomTag style={{ flex: 1 }} role="list">
          <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
            <BoxedLayout style={styles.boxLayout}>
              <View style={styles.chatContainer}>
                <GiftedChat
                  listViewProps={{
                    contentContainerStyle: {
                      paddingTop: theme.normalize(30),
                      paddingBottom: theme.normalize(90),
                    },
                  }}
                  renderMessage={(props) => {
                    return (
                      <CustomTag tag="div" role="listitem">
                        <Message {...props} />
                      </CustomTag>
                    );
                  }}
                  messages={messages}
                  inverted={false}
                  alwaysShowSend
                  placeholder={t("chat.writeMessage")}
                  onSend={handleSendMessage}
                  renderInputToolbar={(props) => customtInputToolbar(props)}
                  minInputToolbarHeight={70}
                  onPressAvatar={() => {
                    if (thread?.site?.adminContact) {
                      onAvatarPressed(thread?.site?.adminContact);
                    }
                  }}
                  locale={"fr"}
                  renderSend={({ onSend, text, sendButtonProps, ...props }) =>
                    isSending ? (
                      <ActivityIndicator
                        color={theme.colors.greyDark}
                        size="large"
                      />
                    ) : (
                      <Send
                        {...props}
                        // Obligé de faire ça pour ne pas prendre en compte le containerStyle de l'InputToolBar
                        containerStyle={{}}
                        label={t("chat.send")}
                        disabled={!text && selectedImages.length === 0}
                        sendButtonProps={{
                          ...sendButtonProps,
                          accessibilityLabel: undefined,
                          onPress: () => {
                            if (onSend) {
                              onSend(
                                {
                                  text: text?.trim() || "",
                                  //image: selectedImages.map((img) => img.uri),
                                },
                                true
                              );
                            }
                          },
                        }}
                      />
                    )
                  }
                  user={{
                    _id: user?.id || 0,
                  }}
                  renderDay={({ currentMessage, previousMessage }) => {
                    if (
                      currentMessage == null ||
                      isSameDay(currentMessage, previousMessage)
                    )
                      return null;

                    return (
                      <View style={[dayStyles.container]}>
                        <View>
                          <Text
                            style={[dayStyles.text]}
                            role="heading"
                            aria-level="2"
                          >
                            {dayjs(currentMessage.createdAt)
                              .locale("fr")
                              .format("ll")}
                          </Text>
                        </View>
                      </View>
                    );
                  }}
                  renderActions={() =>
                    JImagePicker({
                      setSelectedImages: setSelectedImages,
                      style: styles.touchableContainer,
                    })
                  }
                  renderChatFooter={() =>
                    selectedImages?.length ? (
                      <Row style={styles.selectedImages}>
                        {selectedImages?.map((image, index) => (
                          <Stack
                            key={image.uri}
                            containerStyle={styles.stack}
                            frontView={
                              <TouchableOpacity
                                onPress={() => handleCancelImage(index)}
                                role="button"
                                aria-label={t("labels.deleteDocument")}
                              >
                                <Icon
                                  name={"close-circle-outline"}
                                  type={"MaterialCommunity"}
                                  colorName={"dark"}
                                  sizeName={"larger"}
                                />
                              </TouchableOpacity>
                            }
                            frontViewStyle={styles.removeImage}
                            backView={
                              <Image
                                source={{ uri: image.uri }}
                                style={styles.selectedImage}
                                aria-label={"Image attachée à votre message"}
                              />
                            }
                          />
                        ))}
                      </Row>
                    ) : (
                      <></>
                    )
                  }
                  renderCustomView={({ currentMessage }) => {
                    // Identifier le locuteur
                    const speaker =
                      currentMessage?.user._id === user?.id
                        ? "Vous"
                        : currentMessage?.user.name;

                    return Platform.OS === "web" ? (
                      <span className="sr-only">{speaker} :</span>
                    ) : null;
                  }}
                  renderMessageImage={(props) => {
                    return (
                      <View>
                        {typeof props?.currentMessage?.image === "string" ? (
                          <>{renderItem(props?.currentMessage?.image)}</>
                        ) : Array.isArray(props?.currentMessage?.image) ? (
                          <>
                            {(
                              (props?.currentMessage?.image || []) as string[]
                            ).map((img: string) => {
                              return typeof img === "string"
                                ? renderItem(img)
                                : null;
                            })}
                          </>
                        ) : null}
                      </View>
                    );
                  }}
                  textInputProps={{
                    placeholderTextColor: "#333", // Changez la couleur ici
                  }}
                  parsePatterns={(linkStyle) => [
                    {
                      type: "phone",
                      style: linkStyle,
                      onPress: () => {}, // TODO : mettre le call
                    },
                  ]}
                />
              </View>
            </BoxedLayout>
          </TouchableWithoutFeedback>
        </CustomTag>
      </BackgroundLayout>

      {Boolean(currentZoomedImage) && currentZoomedImage !== "" && (
        <DocumentModal
          modalVisible
          setModalVisible={setCurrentZoomedImage}
          imageUrl={currentZoomedImage}
          onDownload={() => downloadFile(currentZoomedImage)}
        />
      )}
    </>
  );
};
