import React from "react";
import { Card } from "../jlib";
import useTheme from "../../theme/ThemeProvider";
import fnStyles from "./SiteCardStyle";
import { Site } from "../../types/Site";
import { StackNavigationProp } from "@react-navigation/stack";
import { PostsParamsList, SitesParamsList } from "../../navigation";
import { SiteCardContent } from "./SiteCardContent";
import { View } from "react-native";

type Props = {
  item: Site;
  navigation: StackNavigationProp<
    SitesParamsList | PostsParamsList,
    "siteList" & "postsFollowedSites"
  >;
  isWebFooterStyle?: boolean;
  isAppFooterStyle?: boolean;
  isHighlighted?: boolean;
  shouldBeCard?: boolean;
  titleLevel?: number;
  hideDescription?: boolean;
  fromParent?: boolean;
  id?: string;
};

export const SiteCard: React.FC<Props> = ({
  item,
  navigation,
  isWebFooterStyle = false,
  isHighlighted = false,
  isAppFooterStyle = false,
  shouldBeCard = true,
  titleLevel,
  hideDescription = false,
  fromParent = false,
  id = undefined,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return shouldBeCard ? (
    <Card
      id={id}
      style={
        isWebFooterStyle
          ? styles.cardContainerWebFooter
          : isHighlighted
          ? styles.cardContainerHighlighted
          : isAppFooterStyle
          ? styles.cardContainerAppFooter
          : styles.cardContainer
      }
      noShadow={isAppFooterStyle}
      ariaCurrent={isHighlighted}
    >
      <SiteCardContent
        item={item}
        navigation={navigation}
        isHighlighted={isHighlighted}
        titleLevel={titleLevel}
        hideDescription={hideDescription}
        fromParent={fromParent}
      />
    </Card>
  ) : (
    <View
      style={
        isWebFooterStyle
          ? styles.cardContainerWebFooter
          : isHighlighted
          ? styles.cardContainerHighlighted
          : isAppFooterStyle
          ? styles.cardContainerAppFooter
          : styles.cardContainer
      }
      aria-selected={isHighlighted}
    >
      <SiteCardContent
        item={item}
        navigation={navigation}
        isHighlighted={isHighlighted}
        titleLevel={titleLevel}
        hideDescription={hideDescription}
        fromParent={fromParent}
      />
    </View>
  );
};
