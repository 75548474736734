import React from "react";
import { View, ImageBackground } from "react-native";
import { Button, JText, Spacer } from "../jlib";
import fnStyles from "./NoSitesFollowedStyle";
import { StackNavigationProp } from "@react-navigation/stack";
import { PostsParamsList } from "../../navigation";
import useTheme from "../../theme/ThemeProvider";
import { bodyFocus } from "utils/bodyfocus";

type Props = {
  navigation: StackNavigationProp<PostsParamsList, "postsHome">;
};

export const NoLoggedInForPosts: React.FC<Props> = ({ navigation }) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);

  return (
    <View style={styles.container}>
      <ImageBackground
        source={theme.images.pictoClipboard}
        style={{
          marginTop: theme.normalize(100),
          height: theme.normalize(120),
          width: theme.normalize(120),
        }}
      />
      <JText labelKey="post.notLoggedInForPosts" centered style={styles.text} />
      <Spacer height="mediumLarge" backgroundColor={"transparent"} />

      <Button
        onPress={() => {
          // @ts-ignore
          navigation.navigate("params", { screen: "logIn" });
          bodyFocus();
        }}
        labelKey="auth.goToLogin"
        type={"gradient"}
      />
    </View>
  );
};
