import { Site } from "../../types/Site";
//import { StackNavigationProp } from "@react-navigation/stack";
//import { PostsParamsList, SitesParamsList } from "../../navigation";
import { Clickable, Icon, JImage, JText, Row } from "../jlib";
import { View } from "react-native";
import React from "react";
import fnStyles from "./SiteCardStyle";
import useTheme from "../../theme/ThemeProvider";
import FollowSwitch from "components/common/switch/FollowSwitch";
import useSites from "hooks/useSites";
import { SiteDistance } from "./SiteDistance";
import useConfiguration from "../../configuration/ConfigurationProvider";
import BadgeDemo from "components/common/badgeDemo.tsx/BadgeDemo";
import { useLocation } from "hooks";
import truncate from "utils/getTruncatedText";
import { bodyFocus } from "utils/bodyfocus";

const stripHtml = (html: string) => {
  return html?.replace(/<[^>]*>?/gm, "");
};

type Props = {
  item: Site;
  /*navigation: StackNavigationProp<
    SitesParamsList | PostsParamsList,
    "siteList" & "postsFollowedSites"
  >;*/
  isHighlighted?: boolean;
  titleLevel?: number;
  hideDescription?: boolean;
  navigation: any;
  fromParent?: boolean;
};
export const SiteCardContent: React.FC<Props> = ({
  item,
  isHighlighted,
  titleLevel = 2,
  hideDescription,
  navigation,
  fromParent = false,
}) => {
  const { theme } = useTheme();
  const styles = fnStyles(theme);
  const { setSite, sites, filters } = useSites();
  const { locationData } = useLocation();
  const { configuration } = useConfiguration();

  const onNavigate = () => {
    if (fromParent) {
      const childSite = sites?.find((s) => s.id === item.id);
      if (childSite) {
        setSite(childSite);
      }
    } else {
      // @ts-ignore
      setSite(item);
    }
    navigation.navigate("siteDetails", {
      siteId: item.id,
    });
    bodyFocus();
  };

  const noHtmlDescription = stripHtml(item?.descriptionRich);

  return (
    item && (
      <>
        <Clickable
          onPress={onNavigate}
          style={{ flexDirection: "row", width: "100%" }}
        >
          {(item.logo || item.mainImage) && (
            <View>
              <JImage
                type="Uri"
                source={item.logo ? item.logo.url : item.mainImage?.url ?? ""}
                style={styles.image}
                // alt={t("titles.illustrationOf", { title: item.name })}
              />
            </View>
          )}

          {/* Content */}
          <View style={styles.contentContainer}>
            {/* Header */}
            {item?.isDraft && <BadgeDemo />}
            <Row style={styles.header}>
              <View style={styles.headerLeft}>
                <JText
                  label={item.name}
                  isBold
                  sizeName="mediumLarge"
                  style={styles.title}
                  colorName={isHighlighted ? "white" : "dark"}
                  titleLevel={titleLevel}
                  // numberOfLines={1}
                />
              </View>
            </Row>

            {/* Description */}

            <View style={{ gap: theme.sizings.small }}>
              {Boolean(configuration?.sites.display.organization) && (
                <Row>
                  <Icon
                    name={"supervised-user-circle"}
                    type="MaterialIcons"
                    colorName={"transparentDark"}
                    style={[styles.pin, { left: theme.sizings.tiny / 2 }]}
                    sizeName="mediumLarge"
                  />
                  <JText
                    label={item.organization?.name}
                    sizeName="average"
                    colorName={isHighlighted ? "whiteLight" : "dark"}
                    style={styles.pinText}
                  />
                </Row>
              )}

              {(Boolean(item.location) ||
                (item?.siteType?.isGeo && item?.geojson?.lat)) && (
                <Row justify="space-between">
                  {item.location ? (
                    <View style={styles.siteLocalisation}>
                      <Icon
                        name={"location-pin"}
                        type="Entypo"
                        colorName={"infoMedium"}
                        style={[styles.pin, { top: -theme.sizings.tiny / 2 }]}
                      />
                      <JText
                        label={item.location}
                        sizeName="average"
                        colorName={isHighlighted ? "whiteLight" : "dark"}
                        style={styles.pinText}
                      />
                    </View>
                  ) : (
                    <View />
                  )}

                  {item?.siteType?.isGeo &&
                  item?.geojson?.lat &&
                  (filters?.location?.lat || locationData?.lat) ? (
                    <SiteDistance
                      siteLocation={item?.geojson}
                      style={{ marginLeft: theme.sizings.smallMedium }}
                    />
                  ) : null}
                </Row>
              )}

              {!hideDescription && (
                <JText
                  label={truncate(
                    noHtmlDescription
                      ? noHtmlDescription
                      : item.description ?? "",
                    250
                  )}
                  sizeName="mediumLarge"
                  style={styles.description}
                  colorName={isHighlighted ? "whiteLight" : "dark"}
                />
              )}

              {/* Link */}
              <View style={styles.footer}>
                <JText
                  labelKey="link.seeSites"
                  sizeName="average"
                  fontWeight="600"
                  right
                  colorName={isHighlighted ? "whiteLight" : "dark"}
                />
                <Icon
                  name="chevron-right"
                  type="Entypo"
                  colorName="grey"
                  style={styles.footerIcon}
                />
              </View>
            </View>
          </View>
        </Clickable>
        <View style={{ position: "absolute", right: 10 }}>
          <FollowSwitch site={item} navigation={navigation} listPage />
        </View>
      </>
    )
  );
};
