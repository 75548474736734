import {
  Platform,
  StyleProp,
  StyleSheet,
  TouchableOpacity,
  ViewStyle,
} from "react-native";
import React, { LegacyRef } from "react";
import useTheme from "../../../theme/ThemeProvider";

type Props = {
  onPress: () => void;
  hitBox?: number;
  hitBoxLeft?: number;
  hitBoxRight?: number;
  hitBoxTop?: number;
  hitBoxBottom?: number;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
  ariaCurrent?: boolean;
  ariaHasPopup?: string;
  disableTabulationFocus?: boolean;
  expandable?: boolean;
  ariaExpanded?: boolean;
  ariaPressed?: boolean;
  children: React.ReactNode[] | React.ReactNode;
  id?: string;
  ariaLabel?: string;
  role?: any;
};

export const Clickable = React.forwardRef<any, Props>(
  (
    {
      onPress,
      hitBox = 0,
      hitBoxLeft = 0,
      hitBoxRight = 0,
      hitBoxBottom = 0,
      hitBoxTop = 0,
      disabled = false,
      style = {},
      ariaCurrent,
      ariaPressed,
      ariaExpanded,
      ariaHasPopup = undefined,
      disableTabulationFocus = false,
      children,
      id = undefined,
      ariaLabel,
      role = "button",
    }: Props,
    ref
  ) => {
    const { theme } = useTheme();
    const styleToApply = StyleSheet.flatten(style) ?? {};

    if (hitBox) {
      styleToApply.padding = theme.normalize(hitBox);
    }
    if (hitBoxLeft) {
      styleToApply.paddingLeft = theme.normalize(hitBoxLeft);
    }
    if (hitBoxTop) {
      styleToApply.paddingTop = theme.normalize(hitBoxTop);
    }
    if (hitBoxRight) {
      styleToApply.paddingRight = theme.normalize(hitBoxRight);
    }
    if (hitBoxBottom) {
      styleToApply.paddingBottom = theme.normalize(hitBoxBottom);
    }

    return (
      <TouchableOpacity
        onPress={(e) => {
          if (!disabled) {
            onPress();
          }
          if (ariaHasPopup && Platform.OS === "web") {
            try {
              e.target.parentElement?.blur();
            } catch (e) {}
          }
        }}
        ref={ref}
        activeOpacity={disabled ? 0 : 0.7}
        style={styleToApply}
        accessibilityRole={disableTabulationFocus ? "none" : "button"}
        aria-label={ariaLabel}
        aria-current={ariaCurrent}
        aria-pressed={ariaPressed}
        aria-expanded={ariaExpanded}
        aria-hidden={disableTabulationFocus}
        aria-disabled={disabled}
        aria-haspopup={ariaHasPopup}
        disabled={disabled}
        id={id}
        role={role}
      >
        {children}
      </TouchableOpacity>
    );
  }
);
