import React, { useState, useEffect, FC } from "react";
import { TouchableOpacity, Dimensions, Animated, View } from "react-native";
import { Route } from "@react-navigation/native";
import { TabBarItem } from "./TabBarItem";
import {
  NavigationHelpers,
  NavigationState,
  ParamListBase,
  PartialState,
  TabNavigationState,
} from "@react-navigation/native";
import {
  BottomTabDescriptorMap,
  BottomTabNavigationEventMap,
} from "@react-navigation/bottom-tabs/lib/typescript/src/types";
import useTheme from "../../../theme/ThemeProvider";
import { bodyFocus } from "utils/bodyfocus";

type NavigationRoute<
  ParamList extends ParamListBase,
  RouteName extends keyof ParamList
> = Route<Extract<RouteName, string>, ParamList[RouteName]> & {
  state?: NavigationState | PartialState<NavigationState>;
};

type Props = {
  state: TabNavigationState<Record<string, object | undefined>>;
  descriptors: BottomTabDescriptorMap;
  navigation: NavigationHelpers<
    Record<string, object | undefined>,
    BottomTabNavigationEventMap
  >;
  route: NavigationRoute<ParamListBase, keyof ParamListBase>;
  index: number;
};
const ItemInteraction: FC<Props> = ({
  state,
  descriptors,
  navigation,
  route,
  index,
}: Props) => {
  const totalWidth = Dimensions.get("window").width;
  const tabWidth = totalWidth / state.routes.length;
  const [translateValue] = useState(new Animated.Value(0));
  const { theme } = useTheme();

  useEffect(() => {
    const animateSlider = (offset: number) => {
      Animated.spring(translateValue, {
        toValue: offset * tabWidth,
        velocity: 10,
        useNativeDriver: true,
      }).start();
    };

    animateSlider(state.index);
  }, [state.index, tabWidth, translateValue]);

  const { options } = descriptors[route.key];
  const label = route.name;
  const isFocused = state.index === index;

  const onPress = () => {
    const event = navigation.emit({
      type: "tabPress",
      target: route.key,
      canPreventDefault: true,
    });

    if (!isFocused && !event.defaultPrevented) {
      navigation.navigate(route.name);
      //bodyFocus();
    }
    Animated.spring(translateValue, {
      toValue: index * tabWidth,
      velocity: 10,
      useNativeDriver: true,
    }).start();
  };

  const onLongPress = () => {
    navigation.emit({
      type: "tabLongPress",
      target: route.key,
    });
  };

  return (
    <View
      role="listitem"
      style={{
        flex: 1,
        maxHeight: theme.layout.navLeftWidth,
      }}
    >
      <TouchableOpacity
        role="button"
        aria-current={isFocused ? true : undefined}
        aria-label={options.tabBarAccessibilityLabel}
        testID={options.tabBarTestID}
        onPress={onPress}
        onLongPress={onLongPress}
        /* eslint-disable-next-line react-native/no-inline-styles */
        style={{
          flex: 1,
          backgroundColor: theme.colors.transparent,
        }}
        key={index}
      >
        <TabBarItem iconName={label.toString()} isCurrent={isFocused} />
      </TouchableOpacity>
    </View>
  );
};

export default ItemInteraction;
