import React from "react";
import { Platform, View } from "react-native";

const CustomTag = React.memo(({ tag = "div", ...props }: any) => {
  if (Platform.OS === "web") {
    return React.createElement(tag, { ...props });
  }

  return <View {...props} />;
});

export default CustomTag;
